import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Base } from 'src/app/@core/models/Base';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { BaseService } from 'src/app/@core/services/BaseService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAgregarBaseComponent } from './dialog-agregar-base/dialog-agregar-base.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gestiona-base',
  templateUrl: './gestiona-base.component.html',
  styleUrls: ['./gestiona-base.component.scss']
})
export class GestionaBaseComponent implements OnInit, OnDestroy {

  FrmGestBase!: FormGroup;
  principal:number=1;
  detalle:number=0;
  ObjBase!: Base;
  admBase: number=0;
  navigationSubscription?: Subscription;

  Base: Base[]=[];
  displayedColumns: string[] = ['nU_ID_BASE','nU_ANIO_PROMOCION','tX_TURNO','fE_REGISTRO'];
  dataSource = this.Base;

  BaseTable : Base[] = [];
  BaseTableDataSource = new MatTableDataSource(this.BaseTable);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjBaseService: BaseService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestBase=this._formBuilder.group({

    });

    this.admBase=variablesGlobales.usuarioRol.admBase;

    this.LlenaGrilla();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.principal=1;
        this.detalle=0;
        this.ObjBase={nU_ID_BASE:0};
        this.Base=[];
        this.BaseTableDataSource.data=this.Base; 
        this.BaseTableDataSource.paginator=this.paginator;

        var element= document.getElementById("paginadorBase");
        if(element!=null){
          element.style.display='block';
        }

        this.LlenaGrilla();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjBaseService.Listar(1)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Base= data.listabase;
                    this.BaseTableDataSource.data=this.Base; 
                  }                                         
                }); 

              this.BaseTableDataSource.paginator=this.paginator;
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  fnAgregar(){
    const dialogRef = this.dialog.open(DialogAgregarBaseComponent, {
      data: {
        listaBase: this.Base
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  fnGestDirectivo(obj: Base){
    this.principal=0;
    this.detalle=1;
    this.ObjBase=obj;

    var element= document.getElementById("paginadorBase");
    if(element!=null){
      element.style.display='none';
    }
  }

  fnRetornoGestDirectivo(objeto:any){
    this.principal=1;
    this.detalle=0;
    this.ObjBase={nU_ID_BASE:0};

    var element= document.getElementById("paginadorBase");
    if(element!=null){
      element.style.display='block';
    }
  }
}
