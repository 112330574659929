import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { UsuarioRol } from '../models/UsuarioRol';

@Injectable({
    providedIn: 'root'
})

export class UsuarioRolService {
    public url:string = Configuracion.url2 + 'UsuarioRol/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: UsuarioRol){
        variable.nU_CODIGO_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }
}