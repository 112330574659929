import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { IntegranteRequisito } from '../models/IntegranteRequisito';

@Injectable({
    providedIn: 'root'
})

export class IntegranteRequisitoService {
    public url:string = Configuracion.url2 + 'IntegranteRequisito/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: IntegranteRequisito){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;

        return this._http.post<any>(this.url + 'Actualizar', variable);
    }
}