
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{txtTitulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmRegistrarIntegrante"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div *ngIf="opcDetalle===2" style="display: none;">
                    <mat-form-field>
                        <mat-select placeholder="Tipo de Deportista" formControlName="tipo_deportista" (selectionChange)="fnMostrarCampos($event.value)" required>
                            <mat-option value="1">SOCIO</mat-option>
                            <mat-option value="2">NO SOCIO</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleSocio">
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Seleccione Socio" formControlName="codsocio" class="CampoTextArea"
                            [matAutocomplete]="auto1" (focus)="fnListarSocio()" (keydown)="pulsar($event)" [readonly]="opc=='MOD'" required></textarea>
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnSocio">
                            <mat-option class="opcionSelect" *ngFor="let socio of filteredSocio | async" [value]="socio">                            
                                {{socio.tX_NUMERO+' | '+socio.tX_NOMBRE+' '+socio.tX_APELLIDO_PATERNO+' '+socio.tX_APELLIDO_MATERNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div> 
                <div *ngIf="isVisibleEntidad">
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Seleccione Persona" formControlName="codentidad" class="CampoTextArea"
                            [matAutocomplete]="auto2" (focus)="fnListarEntidad()" (keydown)="pulsar($event)" (keyup)="fnLimpiar()" [readonly]="opc=='MOD'" required></textarea>
                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnEntidad" (optionSelected)="fnObtenerArchivos($event.option.value)">
                            <mat-option class="opcionSelect" *ngFor="let entidad of filteredEntidad | async" [value]="entidad">                            
                                {{entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button mat-icon-button (click)="fnRegistrarEntidad('NUE',0)" [disabled]="opc=='MOD'">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
                <div *ngIf="opcDetalle===2">
                    <mat-form-field>
                        <mat-select placeholder="Tipo Asimilado" formControlName="cboAsimilado" required (selectionChange)="fnMostrarEquipo($event.value)">
                            <mat-option value=0>NO ES ASIMILADO</mat-option>
                            <mat-option value=1>ASIMILADO INTERNO</mat-option>
                            <mat-option value=2>ASIMILADO EXTERNO</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field class="fila" *ngIf="isVisibleEquipo">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Equipo de Procedencia" formControlName="codequipo" class="CampoTextArea"
                            [matAutocomplete]="auto3" (focus)="fnListarEquipo()" (keydown)="pulsar($event)" required></textarea>
                        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFnEquipo">
                            <mat-option class="opcionSelect" *ngFor="let equipo of filteredEquipo | async" [value]="equipo">                            
                                {{equipo.nU_ANIO_PROMOCION+' '+equipo.tX_TURNO+' - '+equipo.tX_DENOMINACION}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <!-- <div style="margin-top: 5px;">
                        <label>¿El deportista ha jugado anteriormente?</label><br>
                    </div>
                    <div style="margin-top: 15px;">
                        <mat-radio-group formControlName="rdoParticipacion" (change)="fnMostrarProcedencia($event)">
                            <mat-radio-button value="1">Sí</mat-radio-button>
                            <mat-radio-button value="0" style="margin-left: 20px;">No</mat-radio-button>
                        </mat-radio-group>
                    </div> -->
                </div>
                <div *ngIf="isVisibleProcedencia" style="margin-top: 15px;">
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Seleccione Equipo de Procedencia" formControlName="codequipo_procedencia" class="CampoTextArea"
                            [matAutocomplete]="auto3" (focus)="fnListarEquipo()" (keydown)="pulsar($event)" required></textarea>
                        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFnEquipo">
                            <mat-option class="opcionSelect" *ngFor="let equipo of filteredEquipo | async" [value]="equipo">                            
                                {{equipo.nU_ANIO_PROMOCION+' '+equipo.tX_TURNO+' - '+equipo.tX_DENOMINACION}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="opcDetalle===1">
                    <mat-form-field>
                        <mat-select placeholder="Tipo de Delegado" formControlName="tipo_delegado" required>
                            <mat-option value="15">DELEGADO TITULAR</mat-option>
                            <mat-option value="16">DELEGADO SUPLENTE</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="opcDetalle===1">
                    <mat-form-field class="fila3"> 
                        <input matInput [matDatepicker]="FecInicio" placeholder="Fecha de Inicio" autocomplete="off" formControlName="FecInicio" 
                            [readonly]="opc=='MOD'" required>
                        <mat-datepicker-toggle matSuffix [for]="FecInicio" ></mat-datepicker-toggle>
                        <mat-datepicker #FecInicio [disabled]="opc=='MOD'"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="opcDetalle===1">
                    <mat-form-field class="fila4">
                        <input type="hidden" formControlName="coddocumento_ingreso">
                        <textarea matInput cdkTextareaAutosize placeholder="Documento Designatorio" formControlName="txtdocumento_ingreso" 
                            class="CampoTextArea" required readonly></textarea>
                    </mat-form-field>
                    <button mat-icon-button [disabled]="opc=='MOD'" (click)="fnBuscarDocumento(1)">
                        <mat-icon fontSet="material-icons-outlined">find_in_page</mat-icon>
                    </button>
                </div> 
                <div *ngIf="opcDetalle===1 && opc==='MOD'">
                    <mat-form-field class="fila3"> 
                        <input matInput [matDatepicker]="FecTermino" placeholder="Fecha de Término" autocomplete="off" formControlName="FecTermino" 
                            [readonly]="!isVisibleGuardar" required>
                        <mat-datepicker-toggle matSuffix [for]="FecTermino" ></mat-datepicker-toggle>
                        <mat-datepicker #FecTermino [disabled]="!isVisibleGuardar"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="opcDetalle===1 && opc==='MOD'">
                    <mat-form-field class="fila4">
                        <input type="hidden" formControlName="coddocumento_termino">
                        <textarea matInput cdkTextareaAutosize placeholder="Documento de Término" formControlName="txtdocumento_termino" 
                            class="CampoTextArea" required readonly></textarea>
                    </mat-form-field>
                    <button mat-icon-button [disabled]="!isVisibleGuardar" (click)="fnBuscarDocumento(2)">
                        <mat-icon fontSet="material-icons-outlined">find_in_page</mat-icon>
                    </button>
                </div><br><br>
                <fieldset class="borderFieldset"><legend class="estiloLegend">Archivos</legend>
                    <div style="margin-top: 10px;margin-bottom: 20px;" *ngIf="isVisibleGuardar" >
                        <button mat-icon-button class="tooltips_fab" (click)="fnAgregar()">
                            <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                            <span>Añadir</span>
                        </button>
                    </div>
                    <div class="example-container">
                        <table mat-table [dataSource]="ArchivoTableDataSource">
                            <ng-container matColumnDef="nU_ID_ARCHIVO">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="fnQuitar(element)" *ngIf="isVisibleGuardar">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                        </button>
                                        <button mat-menu-item (click)="fnDescargar(element)">
                                            <mat-icon fontSet="material-icons-outlined">get_app</mat-icon>
                                            <span>Descargar</span>
                                        </button>
                                    </mat-menu>                            
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_NOMBRE">
                                <th mat-header-cell *matHeaderCellDef>NOMBRE</th>
                                <td mat-cell *matCellDef="let element">
                                    {{(element.nU_ID_ARCHIVO==0)?element.tX_NOMBRE:element.tX_NOMBRE_ARCHIVO}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_REQUISITO">
                                <th mat-header-cell *matHeaderCellDef>REQUISITO</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_REQUISITO}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tX_DESCRIPCION">
                                <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_DESCRIPCION}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </fieldset>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" *ngIf="isVisibleGuardar" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>
