import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActualizarDatosComponent } from './utilitarios/actualizar-datos/actualizar-datos.component';
import { GestionaDocumentoComponent } from './movimientos/gestiona-documento/gestiona-documento.component';
import { InicioComponent } from './inicio/inicio.component';
import { MenuComponent } from './menu.component';
import { FichaInscripcionComponent } from './consultas/ficha-inscripcion/ficha-inscripcion.component';
import { GestionaEquipoComponent } from './eventos/gestiona-equipo/gestiona-equipo.component';
import { GestionaBaseComponent } from './movimientos/gestiona-base/gestiona-base.component';
import { GestionaUsuarioComponent } from './utilitarios/gestiona-usuario/gestiona-usuario.component';
import { ActualizaClaveComponent } from './utilitarios/actualiza-clave/actualiza-clave.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [   
      {
          path: 'inicio',
          component: InicioComponent,
      },
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'GestionaDocumento',
        component: GestionaDocumentoComponent,
      },
      {
        path: 'ActualizarDatos',
        component: ActualizarDatosComponent,
      },
      {
        path: 'GestionaEquipo',
        component: GestionaEquipoComponent,
      },
      {
        path: 'FichaInscripcion',
        component: FichaInscripcionComponent,
      },
      {
        path: 'GestionaBase',
        component: GestionaBaseComponent,
      },
      {
        path: 'GestionaUsuario',
        component: GestionaUsuarioComponent,
      },
      {
        path: 'ActualizaClave',
        component: ActualizaClaveComponent,
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
