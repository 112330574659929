<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Agregar Requisito</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarRequisito"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila1">
                        <mat-select class="valueSelect" placeholder="Requisito" formControlName="codrequisito">
                            <mat-option class="opcionSelect" *ngFor="let elem of Requisito" [value]="elem.nU_ID_REQUISITO"
                                (click)="txtrequisito=elem.tX_DESCRIPCION!">
                                {{elem.tX_DESCRIPCION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Descripción" formControlName="txtdescripcion" class="CampoTextArea" 
                            (keydown)="pulsar($event)" autocomplete="off" required></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <input type="file" formControlName="archivo" style="font-size: 11.5px; color: #09C;" 
                        (change)="ngChangeFila($event)" accept=".pdf, .jpg, .jpeg, .png, .PDF, .JPG, .JPEG, .PNG">
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;margin-top: 20px;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Agregar()">
        <mat-icon fontSet="material-icons-outlined">add_box</mat-icon>
        <span>Agregar</span>
    </button>
</div>











