import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Aux_Solicitud } from '../models/Aux_Solicitud';

@Injectable({
    providedIn: 'root'
})

export class RequisitoService {
    public url:string = Configuracion.url2 + 'Requisito/';
    
    constructor(private _http: HttpClient) { }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_REQUISITO":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Listar(){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":0}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
    
}