export let variablesGlobales: any={
    autentica:{
        tX_LOGIN:'EXACEMBA_SIG',
        tX_CLAVE: '3X4CEM8A.sig',
        tX_IP:'',
        tX_NOMBRE_EQUIPO:''
    },
    token:'',
    usuario:{
        codusu:0,
        numdoc:'',
        nombre:'',
        apepaterno:'',
        apematerno:'',
        nombreCompleto:'',
        codentidad:0
    },
    usuarioOficinaCargo:{
        codOficina:0,
        nomOficina:'',
        nomCargo:'',
        codCargo:0
    },
    usuarioRol:{
        admBase:0,
        admEquipo:0,
        admDelegado:0,
        admDeportista:0,
        isVisibleData:0
    },
    time_Out: 1800, // tiempo en segundos para el cierre de sesión.
    menu:{},
    codevento:0,
    aplicacion: 'SIG-EXACEMBA-FRONT-WEB'
}