import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class ProvinciaService {
    public url:string = Configuracion.url3 + 'Provincia/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, codigo: string){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "TX_CODIGO_BUSQUEDA":codigo}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}