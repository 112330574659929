<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestNotaPartido">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">
                    Nro.: {{ObjFecha.nU_SECUENCIA+' - Fecha: '+getFormatFecha(0,ObjFecha.fE_PROGRAMADO!)+' - Secuencia: '+ObjPartido.nU_SECUENCIA+', Disciplina: '+txtDisciplina}}
                </mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar" (click)="fnAgregarNota(0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                </div>
                <h3 class="SubTitulo">Relación de Notas</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="NotaPartidoTableDataSource">
                        <ng-container matColumnDef="nU_ID_NOTAPARTIDO">
                            <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                            <td mat-cell *matCellDef="let element" class="priority-1">
                                <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar" 
                                        (click)="fnQuitar(element.nU_ID_NOTAPARTIDO)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                    </button> 
                                </mat-menu>                       
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_ID_TIPO">
                            <th mat-header-cell *matHeaderCellDef class="priority-2">¿Quién?</th>
                            <td mat-cell *matCellDef="let element" class="priority-2">{{element.tX_TIPO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_ID_ENTIDAD">
                            <th mat-header-cell *matHeaderCellDef class="priority-3">Persona</th>
                            <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_DENOMINACION}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_EQUIPO">
                            <th mat-header-cell *matHeaderCellDef class="priority-4">Equipo</th>
                            <td mat-cell *matCellDef="let element" class="priority-4">{{(element.tX_EQUIPO!=null && element.tX_EQUIPO.trim()!='')?element.tX_EQUIPO:'—'}}</td>
                        </ng-container> 
                        <ng-container matColumnDef="tX_OBSERVACION">
                            <th mat-header-cell *matHeaderCellDef class="priority-5">Observación</th>
                            <td mat-cell *matCellDef="let element" class="priority-5">{{element.tX_OBSERVACION}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_ID_ESTADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-6">Estado</th>
                            <td mat-cell *matCellDef="let element" class="priority-6">{{element.tX_ESTADO}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div> 
                <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator> 
            </mat-card-content>
        </mat-card> 
    </form>      
</div>

