
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Agregar Equipo</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarEquipo"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Base" formControlName="codbase" class="CampoTextArea"
                            [matAutocomplete]="auto" (focus)="fnListarBase()" (keydown)="pulsar($event)" (keyup)="fnLimpiar()" required></textarea>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnBase">
                            <mat-option class="opcionSelect" *ngFor="let base of filteredBase | async" [value]="base">                            
                                {{base.nU_ANIO_PROMOCION+' - '+base.tX_TURNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Denominación" formControlName="txtdenominacion" class="CampoTextArea" 
                            (keydown)="pulsar($event)" maxlength="10" required></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-select placeholder="Serie" formControlName="cboserie" required>
                            <mat-option value="A">A</mat-option>
                            <mat-option value="B">B</mat-option>
                            <mat-option value="C">C</mat-option>
                            <mat-option value="D">D</mat-option>
                            <mat-option value="E">E</mat-option>
                            <mat-option value="F">F</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>











