<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestPlanilla">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">
                    Nro.: {{ObjFecha.nU_SECUENCIA+' - Fecha: '+getFormatFecha(0,ObjFecha.fE_PROGRAMADO!)+' - Secuencia: '+ObjPartido.nU_SECUENCIA+', Disciplina: '+txtDisciplina}}
                </mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 25px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar" (click)="fnAgregarPlanilla(0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir Deportista</span>
                    </button>
                    <button mat-mini-fab color="btnStyleWO" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar && isVisibleWalkOver" (click)="fnAgregarWO()">WO
                        <span>Añadir WO</span>
                    </button>
                </div>
                <div>
                    <mat-form-field class="Fila0"> 
                        <mat-select style="width: 110px;" placeholder="Filtrar Equipo" formControlName="cboequipopartido" required (selectionChange)="fnListarPlanilla($event.value)">
                            <mat-option *ngFor="let item of EquipoPartido" [value]="item.nU_ID_EQUIPOPARTIDO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <h3 class="SubTitulo" style="margin-top: 20px;">Relación de Jugadores {{(txtEquipo!=null)?'del Equipo: '+txtEquipo:''}}</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="PlanillaJuegoTableDataSource">
                        <ng-container matColumnDef="nU_ID_PLANILLAJUEGO">
                            <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                            <td mat-cell *matCellDef="let element" class="priority-1">
                                <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu" *ngIf="isBotonAgregar" >
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item 
                                        (click)="fnQuitar(element.nU_ID_PLANILLAJUEGO)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                    </button> 
                                </mat-menu>                       
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tX_ENTIDAD">
                            <th mat-header-cell *matHeaderCellDef class="priority-2">Deportista</th>
                            <td mat-cell *matCellDef="let element" class="priority-2">{{element.tX_ENTIDAD}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_TIPO_INTEGRANTEPARTIDO">
                            <th mat-header-cell *matHeaderCellDef class="priority-3"></th>
                            <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_TIPO_INTEGRANTEPARTIDO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_CAMISETA">
                            <th mat-header-cell *matHeaderCellDef class="priority-4">Camiseta</th>
                            <td mat-cell *matCellDef="let element" class="priority-4">{{(element.nU_CAMISETA!=null)?element.nU_CAMISETA:''}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_CARNET">
                            <th mat-header-cell *matHeaderCellDef class="priority-5">Carnet</th>
                            <td mat-cell *matCellDef="let element" class="priority-5">{{element.nU_CARNET}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_ASIMILADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-6">PR</th>
                            <td mat-cell *matCellDef="let element" class="priority-6">{{element.tX_ASIMILADO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_CAPITAN">
                            <th mat-header-cell *matHeaderCellDef class="priority-7">CP</th>
                            <td mat-cell *matCellDef="let element" class="priority-7">{{(element.nU_CAPITAN==1)?'SI':'NO'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_TA">
                            <th mat-header-cell *matHeaderCellDef class="priority-8">TA</th>
                            <td mat-cell *matCellDef="let element" class="priority-8">
                                {{(element.nU_TA!=null)?element.nU_TA:''}}
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && element.nU_TA==null && element.nU_TR==null"
                                    (click)="fnAgregarInfraccion(0,element)">
                                        <mat-icon fontSet="material-icons-outlined">add</mat-icon>
                                        <span>Agregar</span>
                                </button> 
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && element.nU_TA!=null"
                                    (click)="fnQuitarInfraccion(0,element.nU_ID_PLANILLAJUEGO)">
                                        <mat-icon fontSet="material-icons-outlined">remove</mat-icon>
                                        <span>Quitar</span>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_TR">
                            <th mat-header-cell *matHeaderCellDef class="priority-9">TR</th>
                            <td mat-cell *matCellDef="let element" class="priority-9">
                                {{(element.nU_TR!=null)?element.nU_TR:''}}
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && element.nU_TR==null"
                                    (click)="fnAgregarInfraccion(1,element)">
                                        <mat-icon fontSet="material-icons-outlined">add</mat-icon>
                                        <span>Agregar</span>
                                </button> 
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && element.nU_TR!=null"
                                    (click)="fnQuitarInfraccion(1,element.nU_ID_PLANILLAJUEGO)">
                                        <mat-icon fontSet="material-icons-outlined">remove</mat-icon>
                                        <span>Quitar</span>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_GL">
                            <th mat-header-cell *matHeaderCellDef class="priority-10">GOL</th>
                            <td mat-cell *matCellDef="let element" class="priority-10">
                                {{(element.nU_GL!=null)?element.nU_GL:''}}
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar" 
                                        (click)="fnGestGolesInfracciones(element)">
                                            <mat-icon fontSet="material-icons-outlined">sports_soccer</mat-icon>
                                            <span>Goles</span>
                                    </button>
                                </mat-menu>   
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div> 
                <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator> 
            </mat-card-content>
        </mat-card> 
    </form>      
</div>