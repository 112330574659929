import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Equipo } from '../models/Equipo';

@Injectable({
    providedIn: 'root'
})

export class EquipoService {
    public url:string = Configuracion.url2 + 'Equipo/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: Equipo){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    Listar(opc: number, codigo: number, codigo1: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_BUSQUEDA1":codigo1,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    ValidaUsuario(nU_ID_EQUIPO: number,  opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":nU_ID_EQUIPO,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
                            //console.log(bodyServicio);
        return this._http.post<any>(this.url + 'ValidaUsuario', bodyServicio);
    }

    Obtener(codigo: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
                            //console.log(bodyServicio);
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }
}