<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)"
        >
      <mat-toolbar class="menu">MENÚ</mat-toolbar>
      <mat-nav-list>
        <div *ngFor="let item of Menu">
          <mat-list-item class="itemMenu" (click)="fnObtenerModulo(item)">
            <button mat-button mat-icon-button [disableRipple]="true" class="btnStyle">
              {{item.tX_NOMBRE_MENU}}
              <mat-icon class="menu-button" [ngClass]="{'rotated' : item.nU_EXPANDIR_MENU}">expand_more</mat-icon>
            </button>
          </mat-list-item>
          <div class="submenu" *ngIf="item.children && item.children.length > 0" [ngClass]="{'expanded' : item.nU_EXPANDIR_MENU}">
            <mat-nav-list>
              <mat-list-item *ngFor="let element of item.children" class="menuitem">        
                <a mat-list-item class="subitemmenu" (click)="fnRuta(item.nU_ID_MENU, element)">{{element.tX_NOMBRE_MENU}}</a>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </div>
        <mat-list-item class="itemMenu" (click)="CerrarSesion()">
          <button mat-button mat-icon-button [disableRipple]="true" class="btnStyle">{{MenuCierre.tX_NOMBRE_MENU}}</button>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
      <div class="cabecera">
        <mat-toolbar class="mat-toolbar_principal">         
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()">          
            <mat-icon aria-label="Side nav toggle icon" style="color: white;">menu</mat-icon>
          </button>
          <span style="text-align: start;"> 
              <div>
                <p class="titulo">{{txtTitulo}}</p>
                <p class="subtitulo">{{usuarioname}}</p>
              </div>
          </span>
          <span style="flex: 1 1 auto;"></span>
        </mat-toolbar>   
        <mat-toolbar style="height: 5px; background-color: rgba(25, 115, 184);"></mat-toolbar> 
      </div>
      <div class="pagina">
        <router-outlet></router-outlet>       
      </div>     
      <ng-content>        
      </ng-content>
      <div style="width: 100%;"> 
        <mat-toolbar class="fondo_pie" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="contenido">  
            <div class="row">
              <div class="col">
                <!--<a href="https://www.gob.pe/osinfor" target="_blank" style="text-decoration: none;">
                  <strong class="formato">Osinfor Peru</strong>
                </a>-->
              </div>
              <div class="col">
                <p class="formatoVersion">Versión 1.0</p>
              </div>
            </div>          
          </div>
        </mat-toolbar>
    </div>
    </mat-sidenav-content>    
  </mat-sidenav-container>

