import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Fecha } from '../models/Fecha';

@Injectable({
    providedIn: 'root'
})

export class FechaService {
    public url:string = Configuracion.url2 + 'Fecha/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, codigo: number, codigo1: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_BUSQUEDA1":codigo1,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_FECHA":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Actualizar(ObjFecha: Fecha){
        let bodyServicio: any = ObjFecha;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', bodyServicio);
    }

    Suspender(ObjFecha: Fecha){
        let bodyServicio: any = ObjFecha;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Suspender', bodyServicio);
    }

    Reprogramar(ObjFecha: Fecha){
        let bodyServicio: any = ObjFecha;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Reprogramar', bodyServicio);
    }
}