import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, QrcodeComponent } from '@techiediaries/ngx-qrcode';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Carnet } from 'src/app/@core/models/Carnet';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Evento } from 'src/app/@core/models/Evento';
import { Integrante } from 'src/app/@core/models/Integrante';
import { IntegranteRequisito } from 'src/app/@core/models/IntegranteRequisito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { RptPDFFichaInscripcion } from 'src/app/@core/reports/RptPDFFichaInscripcion';
import { RptPDFListaIntegrantexEquipo } from 'src/app/@core/reports/RptPDFListaIntegrantexEquipo';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { IntegranteService } from 'src/app/@core/services/IntegranteService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { RenderService } from 'src/app/@core/services/RenderService';
import { ReporteService } from 'src/app/@core/services/ReporteService';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { RegistrarIntegranteComponent } from './registrar-integrante/registrar-integrante.component';
import { RptCarnetComponent } from './rpt-carnet/rpt-carnet.component';

@Component({
  selector: 'app-gestiona-integrante',
  templateUrl: './gestiona-integrante.component.html',
  styleUrls: ['./gestiona-integrante.component.scss']
})
export class GestionaIntegranteComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestIntegrante!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() opcDetalle:number=0;
  @Input() ObjEquipo!:Equipo;
  @Input() coddisciplina:number=0;
  @Input() txtDisciplina!:string;
  principal:number=1;
  detalle:number=0;
  codigo:number=0;
  txtTitulo!: string;
  ObjIntegrante!: Integrante;
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  IntegranteRequisito: IntegranteRequisito[]=[];
  foto:string="";
  opcFiltro:number=0;
  ObjArchivo!: Archivo;
  admDelegado: number=0;
  admDeportista: number=0;
  isVisibleData: number=0;
  isVisibleObjeto: number=0;
  isBotonAgregar: number=0;
  ObjCarnet!: Carnet;

  Integrante: Integrante[]=[];
  columns:any[]=[];
  displayedColumns: string[] = [];

  IntegranteTableDataSource = new MatTableDataSource(this.Integrante);

  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: any;
  srcCarnet: any;
  srcFoto: any;
  srcQR: any;
  lblevento!: string;
  lblsigla!: string;
  lblNombre!: string;
  lblEquipo!: string;
  condicion!: string;

  lblEmision!: string;
  lblnumero!: string;
  lblCargo!: string;
  lblDisciplina!: string;

  parametros: any = {
    srcCarnet:null,
    srcFoto:null,
    srcQR: null,
    condicion:null,
    lblevento:null,
    lblsigla:null,
    lblNombre:null,
    lblEquipo:null
  };
  isVisiblecarnet:boolean=false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(QrcodeComponent, { static : false }) parent!: QrcodeComponent;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjEquipoService: EquipoService,
              private ObjIntegranteService: IntegranteService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private ObjEntidadService: EntidadService,
              private pdfLista: RptPDFListaIntegrantexEquipo,
              private pdfFicha: RptPDFFichaInscripcion,
              private ObjReporteService: ReporteService,
              private domSanitizer: DomSanitizer,
              private ObjRenderService: RenderService,
              public dialog: MatDialog) { 
                this.ObjAutenticaService.ValidarApp()
                .subscribe(
                  data => { 
                    if(data.isSuccess){
                      if(data.tx_token.length>0){
                        variablesGlobales.token=data.tx_token;

                        this.ObjEquipoService.ValidaUsuario(this.ObjEquipo.nU_ID_EQUIPO,this.opcDetalle)
                          .subscribe(
                            data => { 
                              //console.log(data);
                              if(data.isSuccess){
                                if (data.tieneacceso){
                                  this.isBotonAgregar=1
                                  //console.log(this.isBotonAgregar);
                                }
                                else{
                                  this.isBotonAgregar=0
                                  //console.log(this.isBotonAgregar);
                                }
                                  
                            }
                          },
                          error => {
                            //////console.log(error);
                          }   
                        );
                      }
                      else{
                          //////console.log("Autenticación inválida: "+data.tx_Mensaje);
                      }
                    }
                  },
                  error => {
                    //////console.log(error);
                  }   
                );
              }

  ngOnInit(): void {
    this.FrmGestIntegrante=this._formBuilder.group({
      cboFiltro:["1"],
    });

    this.admDelegado=variablesGlobales.usuarioRol.admDelegado;
    this.admDeportista=variablesGlobales.usuarioRol.admDeportista;
    this.isVisibleData=variablesGlobales.usuarioRol.isVisibleData;

    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    this.fnListarFiltro(this.FrmGestIntegrante.controls["cboFiltro"].value);
    //console.log(this.isBotonAgregar);
  }

  fnListarFiltro(opc:string){
    var data:any=[];
    if(this.opcDetalle===1){
      // if(this.admDelegado){
      //   this.isVisibleObjeto=1;
      // }

      if(opc==="1"){
        this.opcFiltro=1;

        data=[
          {idcolumna: "nU_ID_INTEGRANTE", cabecera: "", class: "dlv_nU_ID_INTEGRANTE"},
          {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "dlv_tX_NUMERO"},
          {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "dlv_tX_NOMBRE_COMPLETO"},
          {idcolumna: "tX_CARGO", cabecera: "Tipo Delegado", class: "dlv_tX_CARGO"}
        ];

        if(this.isVisibleData){
          data.push(
            {idcolumna: "tX_CORREO", cabecera: "Correo", class: "dlv_tX_CORREO"},
            {idcolumna: "tX_TELEFONO", cabecera: "Teléfono", class: "dlv_tX_TELEFONO"}
          );
        }

        data.push(
          {idcolumna: "fE_INICIO", cabecera: "Inicio", class: "dlv_fE_INICIO"},
          {idcolumna: "tX_DOCUMENTO_INICIO", cabecera: "Documento de Designación", class: "dlv_tX_DOCUMENTO_INICIO"},
          {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "dlv_fE_REGISTRO"}
        );
      }
      else {
        this.opcFiltro=2;

        data=[
          {idcolumna: "nU_ID_INTEGRANTE", cabecera: "", class: "dlc_nU_ID_INTEGRANTE"},
          {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "dlc_tX_NUMERO"},
          {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "dlc_tX_NOMBRE_COMPLETO"},
          {idcolumna: "tX_CARGO", cabecera: "Tipo Delegado", class: "dlc_tX_CARGO"}
        ];

        if(this.isVisibleData){
          data.push(
            {idcolumna: "tX_CORREO", cabecera: "Correo", class: "dlc_tX_CORREO"},
            {idcolumna: "tX_TELEFONO", cabecera: "Teléfono", class: "dlc_tX_TELEFONO"}
          );
        }

        data.push(
          {idcolumna: "fE_INICIO", cabecera: "Inicio", class: "dlc_fE_INICIO"},
          {idcolumna: "tX_DOCUMENTO_INICIO", cabecera: "Documento de Designación", class: "dlc_tX_DOCUMENTO_INICIO"},
          {idcolumna: "fE_TERMINO", cabecera: "Término", class: "dlc_fE_TERMINO"},
          {idcolumna: "tX_DOCUMENTO_TERMINO", cabecera: "Documento de Término", class: "dlc_tX_DOCUMENTO_TERMINO"},
          {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "dlc_fE_REGISTRO"},
          {idcolumna: "fE_MODIFICA", cabecera: "Última Actualización", class: "dlc_fE_MODIFICA"}
        );
      }
    }
    else {
      // if(this.admDeportista){
      //   this.isVisibleObjeto=1;
      // }

      this.opcFiltro=3;

      data=[
        {idcolumna: "nU_ID_INTEGRANTE", cabecera: "", class: "dp_nU_ID_INTEGRANTE"},
        {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "dp_tX_NUMERO"},
        {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "dp_tX_NOMBRE_COMPLETO"}
      ];

      if(this.isVisibleData){
        data.push(
          {idcolumna: "tX_CORREO", cabecera: "Correo", class: "dp_tX_CORREO"},
        {idcolumna: "tX_TELEFONO", cabecera: "Teléfono", class: "dp_tX_TELEFONO"}
        );
      }

      data.push(
        {idcolumna: "tX_ASIMILADO", cabecera: "Asimilado", class: "dp_tX_ASIMILADO"},
        //{idcolumna: "tX_TIPOASIMILADO", cabecera: "Tipo Asimilado", class: "dp_tX_TIPOASIMILADO"},
        {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "dp_fE_REGISTRO"}
      );
    }

    this.columns = data.map((obj:any) => {
      return { 
        columnDef: obj.idcolumna,
        header: obj.cabecera,
        class: obj.class,
        //cell: (element: any) => `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}` 
        cell: (element: any) => {
          if(obj.idcolumna==="fE_INICIO" || obj.idcolumna==="fE_TERMINO"){
            return `${element[obj.idcolumna] ? element[obj.idcolumna].substring(0,10) : ``}`
          }
          else if(obj.idcolumna==="fE_REGISTRO" || obj.idcolumna==="fE_MODIFICA"){
            return `${element[obj.idcolumna] ? this.convertFecha(element[obj.idcolumna]) : ``}`
          }
          else{
            return `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}`
          }
        }
      }
    });

    this.displayedColumns = data.map((obj:any) => obj.idcolumna);

    this.Integrante= [];
    this.IntegranteTableDataSource.data=this.Integrante; 
    this.IntegranteTableDataSource.paginator=this.paginator;

    this.LlenaGrilla();
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => {
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Listar(this.opcFiltro,this.ObjEquipo.nU_ID_EQUIPO)
                .subscribe(data => {
                  //console.log(data);
                  if (data.isSuccess){
                    this.Integrante= data.integrante;
                    this.IntegranteTableDataSource.data=this.Integrante;
                  }                                         
                }); 

              this.IntegranteTableDataSource.paginator=this.paginator;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          //////console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  fnNuevo(opc:string,idintegrante:number){
    const dialogRef = this.dialog.open(RegistrarIntegranteComponent, {
      data: {
        coddisciplina: this.coddisciplina,
        objEquipo: this.ObjEquipo,
        listaIntegrante: this.Integrante,
        opcdetalle: this.opcDetalle,
        codIntegrante: idintegrante,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  fnImprimirLista(){
    this.pdfLista.getPdfData(this.ObjEvento, this.ObjEquipo, this.opcDetalle, this.Integrante, this.txtDisciplina);
  }

  fnImprimirFicha(id:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Obtener(id).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjIntegrante=data.integrante;
                      this.IntegranteRequisito=data.listaarchivosadjuntos;
                      this.foto="";

                      var band=0;

                      for(let item of this.IntegranteRequisito){
                        if(item.nU_ID_REQUISITO===3){
                          this.ObjArchivoService.ObtenerTrama(item.nU_ID_ARCHIVO+"."+item.tX_EXTENSION).pipe()      
                            .subscribe(
                              data => {
                                if(data.isSuccess){
                                  this.foto="data:image/"+item.tX_EXTENSION+";base64,"+data.tx_Mensaje;
                                }  

                                this.fnMostrarPDF(this.ObjIntegrante, this.foto);                                
                              },
                              error => {
                                //////console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              }
                            );
                          
                          band=1;
                          break;
                        }
                      }

                      if(band==0){
                        this.fnMostrarPDF(this.ObjIntegrante, this.foto);
                      }
                    }
                  },
                  error => {
                    //////console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //////console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //////console.log(error);
        }   
      );
  }

  fnMostrarPDF(obj: Integrante, foto: string){
    var txtdepartamento: string="", txtprovincia: string="", txtdistrito: string="";

    if(obj.tX_UBIGEO!=null){
      this.ObjDepartamentoService.Listar(0,'').pipe()    
        .subscribe(
          data => {
            if(data.isSuccess){
              this.Departamento= data.data;

              if(this.Departamento.length>0){
                txtdepartamento=this.Departamento.find(m=>m.tX_ID_DEPARTAMENTO==obj.tX_UBIGEO!.substring(0,2))?.tX_DEPARTAMENTO!;

                this.ObjProvinciaService.Listar(1,obj.tX_UBIGEO!.substring(0,2)).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        this.Provincia= data.data;

                        if(this.Provincia.length>0){
                          txtprovincia=this.Provincia.find(m=>m.tX_ID_PROVINCIA==obj.tX_UBIGEO!.substring(0,4))?.tX_PROVINCIA!;

                          this.ObjDistritoService.Listar(0,obj.tX_UBIGEO!.substring(0,4)).pipe()    
                            .subscribe(
                              data => {
                                if(data.isSuccess){
                                  this.Distrito= data.data;
                                  txtdistrito=this.Distrito.find(m=>m.tX_ID_DISTRITO==obj.tX_UBIGEO)?.tX_DISTRITO!;

                                  this.ObjEntidadService.Obtener(1,0).pipe()
                                    .subscribe(
                                      data => {
                                        if(data.isSuccess){
                                          this.pdfFicha.getPdfData(this.ObjEvento,obj,data.entidad.tX_DENOMINACION,foto,txtdepartamento,txtprovincia,txtdistrito, this.ObjEquipo, this.txtDisciplina);
                                        }
                                      },
                                      error => {
                                        //////console.log(error);
                                        Swal.fire({
                                          title:'El servicio solicitado no se encuentra disponible',
                                          icon: 'error',
                                        });
                                      }
                                    );
                                }                                  
                              },
                              error => {
                                //////console.log(error);
                              }
                            );
                        } 
                      }                                  
                    },
                    error => {
                      //////console.log(error);
                    }
                  );
              }
            }                                  
          },
          error => {
            //////console.log(error);
          }
        );
    }
    else{
      this.ObjEntidadService.Obtener(1,0).pipe()
        .subscribe(
          data => {
            if(data.isSuccess){
              this.pdfFicha.getPdfData(this.ObjEvento,obj,data.entidad.tX_DENOMINACION,foto,txtdepartamento,txtprovincia,txtdistrito,this.ObjEquipo, this.txtDisciplina);
            }
          },
          error => {
            //////console.log(error);
            Swal.fire({
              title:'El servicio solicitado no se encuentra disponible',
              icon: 'error',
            });
          }
        );
    }
  }

  fnCambiarEstado(obj:Integrante,estado:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
                
              this.ObjIntegranteService.Obtener(obj.nU_ID_INTEGRANTE).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    obj=data.integrante;
          
                    if(obj.nU_ID_ESTADO==estado){
                      Swal.fire({
                        title: "El registro ya se encuentra "+((estado==2)?"inactivo":"activo"),
                        icon: 'info'});
                    }
                    else{
                      Swal.fire({
                        text: "¿Está seguro de "+((estado==2)?"anular":"activar")+" el registro?",
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText:'No',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí',
                        reverseButtons: true
                      }).then((result) => {
                        if (result.value){
                          obj.nU_ID_ESTADO=estado;
                          
                          this.ObjIntegranteService.Actualizar(obj).pipe()
                            .subscribe(
                              data => {
                                if (data.isSuccess){
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'success'}); 

                                  this.LlenaGrilla();
                                }
                                else{
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'warning'}); 
                                } 
                              },
                              error => {
                                //////console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              },
                            );
                        }
                      });
                    }
                  }       
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //////console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnQuitar(obj:Integrante){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;              
                  obj.nU_ID_ESTADO=0;

                  this.ObjIntegranteService.Actualizar(obj).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 

                          this.LlenaGrilla();
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //////console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      },
                    );
                }
                else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  variablesGlobales.usuarioOficinaCargo.codOficina=0;
                  variablesGlobales.usuarioOficinaCargo.codCargo=0;
                  variablesGlobales.usuarioRol.admBase=0;
                  variablesGlobales.usuarioRol.admEquipo=0;
                  variablesGlobales.usuarioRol.admDelegado=0;
                  variablesGlobales.usuarioRol.admDeportista=0;
                  variablesGlobales.usuarioRol.isVisibleData=0;
                  variablesGlobales.menu={};
                  variablesGlobales.codevento=0;
                  this.router.navigate(['login']);
                }
              }
            },
            error => {
              //////console.log(error);
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }   
          );
      }
    });
  }

  fnDescargarDocumento(opc:number,obj: Integrante){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.Obtener((opc==1)?obj.nU_ID_DOCUMENTO_INICIO!:obj.nU_ID_DOCUMENTO_TERMINO!).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjArchivo={nU_ID_ARCHIVO:0};

                      for(let item of data.listaarchivo){
                        if(item.nU_ID_REQUISITO==2){
                          this.ObjArchivo=item;
                          break;
                        }
                      }

                      if(this.ObjArchivo.nU_ID_ARCHIVO>0){
                        this.ObjArchivoService.ObtenerTrama(this.ObjArchivo.tX_NOMBRE_ARCHIVO!).pipe()    
                          .subscribe(
                            data1 => {
                              if(data1.isSuccess){
                                this.ObjArchivo.trama=data1.tx_Mensaje;
                                this.fnObtenerArchivo(this.ObjArchivo);
                              }  
                              else{
                                Swal.fire({
                                  title:'Archivo no encontrado',
                                  icon: 'info',
                                });
                              }                                
                            },
                            error => {
                              //////console.log(error);
                              Swal.fire({
                                title:'El servicio solicitado no se encuentra disponible',
                                icon: 'error',
                              });
                            }
                          );
                      }
                      else{
                        Swal.fire({
                          title:'No existe archivo disponible para descargar',
                          icon: 'info',
                        });
                      }
                    }
                  },
                  error => {
                    //////console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //////console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //////console.log(error);
        }   
      ); 
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnDetalle(id:number){
    this.principal=0;
    this.detalle=1;
    this.codigo=id;

    var element= document.getElementById("paginadorIntegrante");
    if(element!=null){
      element.style.display='none';
    }
  }

  fnRetornoDetalle(objeto:any){
    this.principal=1;
    this.detalle=0;
    this.codigo=0;

    var element= document.getElementById("paginadorIntegrante");
    if(element!=null){
      element.style.display='block';
    }
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  fnEditarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  Retorno(){
    this.salida.emit(1);
  }

  fnImprimirCarnet(id:number){
    //console.log(variablesGlobales.usuarioOficinaCargo);
    if (variablesGlobales.usuario.codusu===2){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjDocumentoService.ObtenerCarnet(id,3).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        this.ObjCarnet=data.carnet;

                        this.IntegranteRequisito=data.listaarchivosadjuntos;
                        this.srcFoto="";
                        this.srcCarnet="assets/img/"+(this.ObjCarnet.tX_CONDICION=="ASOCIADO"?"carnet_asociado":"carnet_asimilado")+".jpeg";
                        this.value=this.ObjCarnet.tX_LINK;
                        this.lblevento=this.ObjCarnet.tX_DENOMINACION_EVENTO!;
                        this.lblsigla=this.ObjCarnet.tX_SIGLA!;
                        this.lblNombre=this.ObjCarnet.tX_NOMBRE!+"<br>"+this.ObjCarnet.tX_APELLIDO_PATERNO!+" "+this.ObjCarnet.tX_APELLIDO_MATERNO!;
                        this.lblEquipo=this.ObjCarnet.tX_EQUIPO!;
                        this.condicion=this.ObjCarnet.tX_CONDICION!;
                        this.lblEmision=this.ObjCarnet.tX_EMISION!;
                        this.lblnumero=this.ObjCarnet.tX_NUMERO!;
                        this.lblCargo=this.ObjCarnet.tX_CARGO!;
                        this.lblDisciplina=this.ObjCarnet.tX_DISCIPLINA!;

                        var band=0;

                        for(let item of this.IntegranteRequisito){
                          if(item.nU_ID_REQUISITO===3){
                            this.ObjArchivoService.ObtenerTrama(item.nU_ID_ARCHIVO+"."+item.tX_EXTENSION).pipe()      
                              .subscribe(
                                data => {
                                  if(data.isSuccess){
                                    const byteCharacters= atob(data.tx_Mensaje);
                                    const byteArrays = [];

                                    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                      const slice = byteCharacters.slice(offset, offset + 512);

                                      const byteNumbers = new Array(slice.length);
                                      for (let i = 0; i < slice.length; i++) {
                                        byteNumbers[i] = slice.charCodeAt(i);
                                      }

                                      const byteArray = new Uint8Array(byteNumbers);
                                      byteArrays.push(byteArray);
                                    }

                                    const blob = new Blob(byteArrays, {type: 'image/png'});
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                      this.srcFoto = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
                                    };

                                    reader.readAsDataURL(new File([blob],"imagen_qr",
                                      {type: 'image/png'})); 
                                  }  
                                  
                                  this.fnObtenerCarnetPDF();
                                },
                                error => {
                                  //////console.log(error);
                                  Swal.fire({
                                    title:'El servicio solicitado no se encuentra disponible',
                                    icon: 'error',
                                  });
                                }
                              );
                            
                            band=1;
                            break;
                          }
                        }

                        if(band==0){
                          this.fnObtenerCarnetPDF();
                        }
                      }
                    },
                    error => {
                      //////console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //////console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //////console.log(error);
          }   
        );
    }
    else{
      Swal.fire({
        title:'Acceso no permitido: Perfil de usuario incorrecto.',
        icon: 'info',
      });
    }
  }

  fnObtenerCarnetPDF(){
    setTimeout(() => {
      const myBase64 = this.parent.qrcElement.nativeElement.querySelector("img").src;
      const byteCharacters= atob(myBase64.substring(22,myBase64.length));
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: 'image/png'});
      const reader = new FileReader();
      reader.onload = () => {
        this.srcQR = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
      };

      reader.readAsDataURL(new File([blob],"imagen_qr",
        {type: 'image/png'}));
    }, 0);

    setTimeout(() => {
      this.parametros = {
        srcCarnet:this.srcCarnet,
        srcFoto:this.srcFoto,
        srcQR: this.srcQR,
        condicion:this.condicion,
        lblevento:this.lblevento,
        lblsigla:this.lblsigla,
        lblNombre:this.lblNombre,
        lblEquipo:this.lblEquipo,
        lblEmision:this.lblEmision,
        lblnumero:this.lblnumero,
        lblCargo:this.lblCargo,
        lblDisciplina:this.lblDisciplina
      };

      const dialogRef = this.dialog.open(RptCarnetComponent, {
        data: {
          parametros:this.parametros
        },
        disableClose: true 
      });
    }, 1000);
  }
}
