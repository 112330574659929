import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class EventoService {
    public url:string = Configuracion.url2 + 'Evento/';
    
    constructor(private _http: HttpClient) { }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_EVENTO":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Listar(){
        let bodyServicio = {}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}