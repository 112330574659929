<div class="container-fluid py-3">
    <form [formGroup]="FrmLogin" >     
        <div fxLayout="column" fxLayoutAlign="space-around center" class="containers">
            <mat-card fxLayout="column">   
                <p>{{titulo}}</p>
                <mat-card-content fxLayout="column">                       
                    <mat-form-field class="fila">
                        <input matInput aria-label="string" placeholder="Login o Usuario" formControlName="login" maxlength="50" autofocus
                            [readonly]="isReadOnlyLogin">
                    </mat-form-field>
                    <mat-form-field class="fila">
                        <input matInput aria-label="string" type="password" placeholder="Clave" formControlName="clave" maxlength="20">
                    </mat-form-field>  
                    <mat-form-field class="fila" *ngIf="isReadOnlyLogin">
                        <input matInput aria-label="string" type="password" placeholder="Confirmar Clave" formControlName="clave_temp" maxlength="20">
                    </mat-form-field> 
                </mat-card-content>                           
                <mat-card-actions fxLayoutAlign="center" style="margin-bottom: 5px;">
                    <button mat-button mat-raised-button class="btnConsultar" [disabled]="FrmLogin.invalid" (click)="fnValidar()">{{txtButton}}</button>  
                </mat-card-actions>      
            </mat-card> 
        </div>
    </form>
</div>

