import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Base } from '../models/Base';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class BaseService {
    public url:string = Configuracion.url2 + 'Base/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: Base){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    Listar(opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}