import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';

@Injectable({
    providedIn: 'root'
})

export class CargoService {
    public url:string = Configuracion.url2 + 'Cargo/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}