import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Rol } from '../@core/models/Rol';
import { AutenticaService } from '../@core/services/AutenticaService';
import { EntidadService } from '../@core/services/EntidadService';
import { RolService } from '../@core/services/RolService';
import { variablesGlobales } from '../Util/variableGlobal';
import { EventoService } from '../@core/services/EventoService';
import { Evento } from '../@core/models/Evento';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  Menu:any;
  MenuCierre:any;
  codusu: number=0;
  usuarioname!: string;
  UsuarioRol: Rol[]=[];
  Evento: Evento[]=[];
  txtTitulo!: string;
  eventSubscription!: Subscription;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result:any) => result.matches),
      shareReplay()
    );

  constructor(private router:Router,
              private ObjAutenticaService: AutenticaService,
              private ObjRolService: RolService,
              private ObjEventoService: EventoService,
              private breakpointObserver: BreakpointObserver,
              private ObjEntidadService: EntidadService
              ) {
                if(variablesGlobales.usuario.codusu === 0){
                  this.router.navigate(['/login']);
                }
                else{
                  //console.log(variablesGlobales.usuario);
                  this.codusu = variablesGlobales.usuario.codusu;
                  this.usuarioname = "Usuario: "+variablesGlobales.usuario.nombreCompleto;
                }
              }

  ngOnInit() {
    this.ObtenerMenu();
    this.cargarDatos();
  }

  cargarDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObtenerEntidad();
              this.fnListarUsuarioRol();
              this.fnListarEvento();
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          console.log(error);
        }   
      );
  }

  ObtenerEntidad(){
    this.ObjEntidadService.Obtener(1,0).pipe()
      .subscribe(
        data => {
          if(data.isSuccess){
            this.txtTitulo=data.entidad.tX_DENOMINACION;
          }
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  fnListarUsuarioRol(){
    this.ObjRolService.Listar(2,this.codusu).pipe()
      .subscribe(
        data => {
          if(data.isSuccess){
            this.UsuarioRol=data.listaRol;

            for(let item of this.UsuarioRol){
              switch(item.nU_ID_ROL){
                case 1:
                  variablesGlobales.usuarioRol.admBase=1;
                  break;
                case 2:
                  variablesGlobales.usuarioRol.admEquipo=1;
                  break;
                case 3:
                  variablesGlobales.usuarioRol.admDelegado=1;
                  break;
                case 4:
                  variablesGlobales.usuarioRol.admDeportista=1;
                  break;
                case 5:
                  variablesGlobales.usuarioRol.isVisibleData=1;
                  break;
              }
            }
          }
        },
        error => {
          console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  fnListarEvento(){
    this.ObjEventoService.Listar().pipe()
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Evento=data.evento;

            for(let menu of this.Menu){
              if(menu.nU_ID_MENU==10){
                let nro_orden=menu.nU_ORDEN;

                for(let evento of this.Evento){
                  nro_orden++;

                  let model={
                    nU_ID_MENU:evento.nU_ID_EVENTO, 
                    tX_NOMBRE_MENU:evento.tX_DENOMINACION,
                    tX_PATH_COMPONENTE:"GestionaEquipo",
                    nU_ORDEN:nro_orden
                  }

                  menu.children.push(model);
                }

                break;
              }
            }
          }
        },
        error => {
          console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  ObtenerMenu(){     
    var childrenMovimientos:any=[];
    var childrenUtilitarios:any=[];

    if(variablesGlobales.usuarioOficinaCargo.codOficina>0){
      /*childrenMovimientos.push(
        {
          nU_ID_MENU:2, 
          tX_NOMBRE_MENU:"Gestiona Documento", 
          tX_PATH_COMPONENTE:"GestionaDocumento",
          nU_ORDEN:2
        }
      );*/

      childrenUtilitarios.push(
        /*{
          nU_ID_MENU:31, 
          tX_NOMBRE_MENU:"Actualiza Datos de Usuario", 
          tX_PATH_COMPONENTE:"ActualizarDatos",
          nU_ORDEN:31
        },*/
        {
          nU_ID_MENU:32, 
          tX_NOMBRE_MENU:"Gestiona Usuario", 
          tX_PATH_COMPONENTE:"GestionaUsuario",
          nU_ORDEN:32
        }
      );
    }

    childrenMovimientos.push(
      {
        nU_ID_MENU:3, 
        tX_NOMBRE_MENU:"Bases Organizacionales", 
        tX_PATH_COMPONENTE:"GestionaBase",
        nU_ORDEN:3
      }
    );

    childrenUtilitarios.push(
      {
        nU_ID_MENU:33, 
        tX_NOMBRE_MENU:"Cambiar Clave", 
        tX_PATH_COMPONENTE:"ActualizaClave",
        nU_ORDEN:33
      }
    );

    this.Menu=[
      {
        nU_ID_MENU:1, 
        tX_NOMBRE_MENU:"Movimientos", 
        tX_PATH_COMPONENTE:"",
        nU_ORDEN:1,
        nU_EXPANDIR_MENU:false,
        children: childrenMovimientos
      },
      {
        nU_ID_MENU:10, 
        tX_NOMBRE_MENU:"Eventos", 
        tX_PATH_COMPONENTE:"",
        nU_ORDEN:10,
        nU_EXPANDIR_MENU:false,
        children:[]
      }
    ];

    if(variablesGlobales.usuarioOficinaCargo.codOficina>0){
      /*this.Menu.push(
        {
          nU_ID_MENU:20, 
          tX_NOMBRE_MENU:"Consultas", 
          tX_PATH_COMPONENTE:"",
          nU_ORDEN:20,
          nU_EXPANDIR_MENU:false,
          children:[
            /*{
              nU_ID_MENU:21, 
              tX_NOMBRE_MENU:"Consultar Asociado", 
              tX_PATH_COMPONENTE:"",
              nU_ORDEN:21,
            },
            {
              nU_ID_MENU:22, 
              tX_NOMBRE_MENU:"Patrocinar", 
              tX_PATH_COMPONENTE:"",
              nU_ORDEN:22,
            }
            {
              nU_ID_MENU:23, 
              tX_NOMBRE_MENU:"Ficha de Inscripción", 
              tX_PATH_COMPONENTE:"FichaInscripcion",
              nU_ORDEN:23,
            }*/
          /*]
        }
      );*/
    }

    this.Menu.push(
      {
        nU_ID_MENU:30, 
        tX_NOMBRE_MENU:"Utilitarios", 
        tX_PATH_COMPONENTE:"",
        nU_ORDEN:30,
        nU_EXPANDIR_MENU:false,
        children: childrenUtilitarios
      }
    );

    this.MenuCierre={nU_ID_MENU:100, tX_NOMBRE_MENU:"Cerrar Sesión", tX_PATH_COMPONENTE:"",nU_ORDEN:100};

    if(JSON.stringify(variablesGlobales.menu) == '{}'){
      for(let item of this.Menu){
        variablesGlobales.menu['showmodulo_'+item.nU_ID_MENU]=false;
      }
    }
    else{
      for (let key in variablesGlobales.menu) {
        for(let item of this.Menu){
          if(parseInt(key.split("_")[1]) == item.nU_ID_MENU){
            item.nU_EXPANDIR_MENU=variablesGlobales.menu[key];
            break;
          }
        }
      }
    }
  }

  fnObtenerModulo(obj:any){
    obj.nU_EXPANDIR_MENU = !obj.nU_EXPANDIR_MENU;
    variablesGlobales.menu['showmodulo_'+obj.nU_ID_MENU]=obj.nU_EXPANDIR_MENU;
  }

  fnRuta(codmenu: number, obj: any){
    variablesGlobales.codevento=(codmenu==10)?obj.nU_ID_MENU:0;
    this.router.navigate(['/menu/'+obj.tX_PATH_COMPONENTE]);
  }

  CerrarSesion(){
    Swal.fire({
      text: "¿Está seguro de cerrar?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        variablesGlobales.token='';
        variablesGlobales.usuario.codusu=0;
        variablesGlobales.usuarioOficinaCargo.codOficina=0;
        variablesGlobales.usuarioOficinaCargo.codCargo=0;
        variablesGlobales.usuarioRol.admBase=0;
        variablesGlobales.usuarioRol.admEquipo=0;
        variablesGlobales.usuarioRol.admDelegado=0;
        variablesGlobales.usuarioRol.admDeportista=0;
        variablesGlobales.usuarioRol.isVisibleData=0;
        variablesGlobales.menu={};
        variablesGlobales.codevento=0;
        this.router.navigate(['login']);
      }
    }) 
  }
}
