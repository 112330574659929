import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Observable } from 'rxjs';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class ArchivoService {
    public url:string = Configuracion.url2 + 'Archivo/';
    
    constructor(private _http: HttpClient) { }

    EnviarArchivo(oFormData: FormData): Observable<any>{
        return this._http.post<any>(this.url + 'UpLoadFile', oFormData);
    }

    ObtenerTrama(nombre:string){
        let bodyServicio = {"TX_NOMBRE_ARCHIVO":nombre};
        return this._http.post<any>(this.url + 'DownLoadFile', bodyServicio);
    }

    UpLoadFileRelacionado(oFormData: FormData): Observable<any>{
        return this._http.post<any>(this.url + 'UpLoadFileRelacionado', oFormData);
    }

    Listar(opc: number, codigo: number, codigo1: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_BUSQUEDA1":codigo1,
                            "NU_CODIGO_EVENTO":variablesGlobales.codevento}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}