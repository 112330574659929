import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Fecha } from 'src/app/@core/models/Fecha';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { PartidoService } from 'src/app/@core/services/PartidoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-agregar-partido',
  templateUrl: './dialog-agregar-partido.component.html',
  styleUrls: ['./dialog-agregar-partido.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DialogAgregarPartidoComponent implements OnInit {

  FrmAgregarPartido!: FormGroup;
  coddisciplina:number=0;
  ObjFecha!:Fecha;
  codpartido:number=0;
  titulo!: string;
  EquipoLocal: Equipo[]=[];
  EquipoVisita: Equipo[]=[];
  ObjPartido!:Partido;
  estado: number=1;
  isVisibleMotivo: boolean=false;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjEquipoService: EquipoService,
              private ObjPartidoService: PartidoService,
              private dialogRef: MatDialogRef<DialogAgregarPartidoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddisciplina=data.coddisciplina;
                this.ObjFecha=data.objFecha;
                this.codpartido=data.codpartido;
              }

  ngOnInit(): void {
    this.FrmAgregarPartido=this._formBuilder.group({
      txtnumero:[null,Validators.required],
      cboserie:[null,Validators.required],
      cboequipo_local:[null,Validators.required],
      cboequipo_visita:[null,Validators.required],
      FecProgramada:[this.convertFechatoStringData(this.ObjFecha.fE_PROGRAMADO)],
      txthora:[null,Validators.required],
      txtmotivo:["",Validators.required]
    });

    this.titulo="Registrar Partido";

    this.cargaDatos();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(this.codpartido>0){
                this.titulo="Editar Partido";
                this.isVisibleMotivo=true;  

                this.ObjPartidoService.Obtener(this.codpartido).pipe()
                  .subscribe(data => {
                    if(data.isSuccess){
                      this.ObjPartido=data.partido;

                      this.FrmAgregarPartido.controls["txtnumero"].setValue(this.ObjPartido.nU_SECUENCIA?.toString());
                      this.FrmAgregarPartido.controls["cboserie"].setValue(this.ObjPartido.tX_SERIE);
                      this.FrmAgregarPartido.controls["cboserie"].disable();
                      this.FrmAgregarPartido.controls["FecProgramada"].setValue(this.convertFechatoStringData(this.ObjPartido.fE_PROGRAMADO));
                      this.FrmAgregarPartido.controls["txthora"].setValue(this.ObjPartido.fE_PROGRAMADO?.substring(11,16));
                      this.estado=this.ObjPartido.nU_ID_ESTADO!;

                      this.ObjEquipoService.Listar(1,variablesGlobales.codevento,this.coddisciplina)
                        .subscribe(data => {
                          if (data.isSuccess){
                            this.EquipoLocal= data.equipo;
                            this.EquipoLocal=this.EquipoLocal.filter((value,key)=>{
                              return value.tX_SERIE == this.ObjPartido.tX_SERIE;
                            });
                            this.FrmAgregarPartido.controls["cboequipo_local"].setValue(this.ObjPartido.nU_ID_EQUIPO_LOCAL);
                            this.FrmAgregarPartido.controls["cboequipo_local"].disable();

                            this.EquipoVisita= data.equipo;
                            this.EquipoVisita=this.EquipoVisita.filter((value,key)=>{
                              return value.tX_SERIE == this.ObjPartido.tX_SERIE;
                            });
                            this.FrmAgregarPartido.controls["cboequipo_visita"].setValue(this.ObjPartido.nU_ID_EQUIPO_VISITA);
                            this.FrmAgregarPartido.controls["cboequipo_visita"].disable();
                          }                                         
                        });
                    }
                  });
              }
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarEquipo(txtserie: string){
    this.EquipoLocal=[];
    this.EquipoVisita=[];

    this.FrmAgregarPartido.controls["cboequipo_local"].setValue(null);
    this.FrmAgregarPartido.controls["cboequipo_visita"].setValue(null);

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoService.Listar(1,variablesGlobales.codevento,this.coddisciplina)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.EquipoLocal= data.equipo;
                    this.EquipoLocal=this.EquipoLocal.filter((value,key)=>{
                      return value.tX_SERIE == txtserie;
                    });

                    this.EquipoVisita= data.equipo;
                    this.EquipoVisita=this.EquipoVisita.filter((value,key)=>{
                      return value.tX_SERIE == txtserie;
                    });
                  }                                         
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  ValidarSeleccion(opc: number, codequipo: number){
    if(opc==0){
      if (this.FrmAgregarPartido.controls["cboequipo_visita"].value != null &&
        this.FrmAgregarPartido.controls["cboequipo_visita"].value == codequipo) {
          this.alertMsg('El equipo local no puede ser igual al equipo visita');
          this.FrmAgregarPartido.controls["cboequipo_local"].setValue(null);
      }
    }
    else if(opc==1){
      if (this.FrmAgregarPartido.controls["cboequipo_local"].value != null &&
        this.FrmAgregarPartido.controls["cboequipo_local"].value == codequipo) {
          this.alertMsg('El equipo visita no puede ser igual al equipo local');
          this.FrmAgregarPartido.controls["cboequipo_visita"].setValue(null);
      }
    }
  }

  onKeyDownOnlyNumber (input: any, event:KeyboardEvent) {
    var specialKeys: string[] = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Del',
      'Delete',
    ];

    if (specialKeys.indexOf(event.key) !== -1 ||
        (event.key === 'v' && event.ctrlKey === true) ||
        (event.key === 'v' && event.metaKey === true)) {
        return;
    }

    var current: string = input.value;
    var regex=new RegExp(/^\d+$/g);
    var next = current.concat(event.key);

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  handlePasteOnlyNumber(event:ClipboardEvent) {
    var input=event.clipboardData?.getData('text/plain');
    var regex=new RegExp(/^\d+$/g);
  
    if (regex.test(input!)) {
      return true;
    }
    return false;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjPartido={nU_ID_PARTIDO: this.codpartido};
      this.ObjPartido.nU_SECUENCIA=parseInt(this.FrmAgregarPartido.controls["txtnumero"].value);
      this.ObjPartido.fE_PROGRAMADO= this.convertDatetoString(new Date(this.FrmAgregarPartido.controls["FecProgramada"].value))+
                                    'T'+this.FrmAgregarPartido.controls["txthora"].value;
      this.ObjPartido.tX_OBSERVACION=this.FrmAgregarPartido.controls["txtmotivo"].value;
      this.ObjPartido.nU_ID_ESTADO=this.estado;

      if(this.codpartido>0){
        this.Procesar(2, this.ObjPartido);
      }
      else{
        this.ObjPartido.nU_ID_FECHA=this.ObjFecha.nU_ID_FECHA;
        this.ObjPartido.nU_ID_EVENTO=variablesGlobales.codevento;
        this.ObjPartido.nU_ID_DISCIPLINA=this.coddisciplina;
        this.ObjPartido.tX_SERIE=this.FrmAgregarPartido.controls["cboserie"].value;
        this.ObjPartido.nU_ID_EQUIPO_LOCAL=this.FrmAgregarPartido.controls["cboequipo_local"].value;
        this.ObjPartido.nU_ID_EQUIPO_VISITA=this.FrmAgregarPartido.controls["cboequipo_visita"].value;

        this.Procesar(1, this.ObjPartido);
      }
      
    }
  }

  Procesar(opc: number, obj: Partido){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(opc==1){
                this.ObjPartidoService.Actualizar(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else if(opc==2){
                this.ObjPartidoService.Editar(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  ValidarCampos(){
    if (this.FrmAgregarPartido.controls["txtnumero"].value == null || 
      this.FrmAgregarPartido.controls["txtnumero"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarPartido.controls["txtnumero"].value)) {
        this.alertMsg('Ingrese Nro. Secuencia');
        return false;
    }

    if (this.FrmAgregarPartido.controls["cboserie"].value == null || 
      this.FrmAgregarPartido.controls["cboserie"].value.length == 0) {
        this.alertMsg('Seleccione Serie');
        return false;
    }

    if (this.FrmAgregarPartido.controls["cboequipo_local"].value == null || 
      this.FrmAgregarPartido.controls["cboequipo_local"].value.length == 0) {
        this.alertMsg('Seleccione Equipo Local');
        return false;
    }

    if (this.FrmAgregarPartido.controls["cboequipo_visita"].value == null || 
      this.FrmAgregarPartido.controls["cboequipo_visita"].value.length == 0) {
        this.alertMsg('Seleccione Equipo Visita');
        return false;
    }

    if (this.FrmAgregarPartido.controls["txthora"].value == null || 
      this.FrmAgregarPartido.controls["txthora"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarPartido.controls["txthora"].value)) {
        this.alertMsg('Ingrese Hora Programada');
        return false;
    }

    if(this.isVisibleMotivo){
      if (this.FrmAgregarPartido.controls["txtmotivo"].value == null || 
        this.FrmAgregarPartido.controls["txtmotivo"].value.length == 0 || 
        /^\s+$/.test(this.FrmAgregarPartido.controls["txtmotivo"].value)) {
          this.alertMsg('Ingrese Nota u observación');
          return false;
      }
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  convertDatetoString(fecha: Date) {
    const dia: string = fecha.getDate().toString().padStart(2, '0');
    const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio: string = fecha.getFullYear().toString();
    return anio + '-' + mes + '-' + dia; 
  }

  convertFechatoStringData(fecha: any){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  Close() {
    this.dialogRef.close();
  }

}
