
<mat-sidenav-container>
    <mat-sidenav-content fxLayout="column">
      <div style="position: fixed;width: 100%;z-index: 60;">
        <mat-toolbar class="mat-toolbar_principal">         
          <span style="text-align: start;"> 
              <div style="display:flex; align-items:center;">
                <p class="titulo">{{txtTitulo}}</p>
              </div>
          </span>
        </mat-toolbar> 
        <mat-toolbar style="height: 5px; background-color: rgba(25, 115, 184);"></mat-toolbar> 
      </div>     
      <div class="pagina">
        <div class="div_centrado">
            <div *ngIf="isVisibleFrm">
                <form [formGroup]="FrmConsultarQR"> 
                    <mat-card fxLayout="column">
                        <mat-card-content fxLayout="column">
                            <fieldset class="borderFieldset"><legend class="estiloLegend">Datos de Carnet</legend>
                                <div class="cardList">
                                    <div class="cardListItem">
                                        <div>
                                            <mat-form-field class="fila1"> 
                                                <input matInput aria-label="string" placeholder="Tipo de documento de identidad" formControlName="txttipodoc" readonly>
                                            </mat-form-field>
                                            <mat-form-field class="fila2"> 
                                                <input matInput aria-label="string" placeholder="Número" formControlName="txtnumero" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="fila3">
                                                <textarea matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="txtnombre" class="CampoTextArea" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila3">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Carnet" formControlName="txtcarnet" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila2">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Fecha de Emisión" formControlName="txtfecemision" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila3">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Denominación" formControlName="txtevento" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila3">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Cargo - condición" formControlName="txtcargo_condicion" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila4">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Equipo" formControlName="txtequipo" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                          <mat-form-field class="fila5">
                                            <textarea matInput cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize" placeholder="Disciplina" formControlName="txtdisciplina" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                        <div>
                                          <mat-form-field class="fila1">
                                              <textarea matInput cdkTextareaAutosize
                                                  #autosize="cdkTextareaAutosize" placeholder="Estado" formControlName="txtestado" class="CampoTextArea" readonly></textarea>
                                          </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="cardListItem">
                                        <div class="center">
                                            <mat-card class="foto-card">
                                                <img mat-card-image *ngIf="isVisibleFoto" [src]="src" class="tamanioImagen">
                                                <div *ngIf="!isVisibleFoto" class="center">
                                                    <p style="text-align: center;">NO SE CARGÓ FOTO</p>
                                                </div>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </mat-card-content>
                    </mat-card>    
                </form>
            </div>
            <div *ngIf="msjError!=''">
                <div style="display:flex; justify-content: center; align-items: center;">
                    <mat-card fxLayout="column" style="width: 350px;">  
                        <mat-card-title style="text-align: center;">{{msjError}}</mat-card-title>
                    </mat-card>
                </div>
            </div>
        </div>
      </div>     
      <ng-content>        
      </ng-content>
      <div style="width: 100%;"> 
        <mat-toolbar class="fondo_pie" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="contenido">  
            <div class="row">
              <div class="col">
                <!--<a href="https://www.gob.pe/osinfor" target="_blank" style="text-decoration: none;">
                  <strong class="formato">Osinfor Peru</strong>
                </a>-->
              </div>
              <div class="col">
                <p class="formatoVersion">versión</p>
              </div>
            </div>          
          </div>
        </mat-toolbar>
      </div>
    </mat-sidenav-content>    
</mat-sidenav-container>
    