import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Partido } from '../models/Partido';

@Injectable({
    providedIn: 'root'
})

export class ReclamoService {
    public url:string = Configuracion.url2 + 'Reclamo/';
    
    constructor(private _http: HttpClient) { }

    ActualizarPartido(ObjPartido: Partido){
        let bodyServicio: any = ObjPartido;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'ActualizarPartido', bodyServicio);
    }
}