import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Partido } from '../models/Partido';

@Injectable({
    providedIn: 'root'
})

export class PartidoService {
    public url:string = Configuracion.url2 + 'Partido/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, codigo: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_PARTIDO":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Actualizar(ObjPartido: Partido){
        let bodyServicio: any = ObjPartido;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', bodyServicio);
    }

    Editar(ObjPartido: Partido){
        let bodyServicio: any = ObjPartido;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Editar', bodyServicio);
    }

    Suspender(ObjPartido: Partido){
        let bodyServicio: any = ObjPartido;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Suspender', bodyServicio);
    }

    Quitar(ObjPartido: Partido){
        let bodyServicio: any = ObjPartido;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Quitar', bodyServicio);
    }

    Inicia(codigo: number){
        let bodyServicio = {"NU_ID_PARTIDO":codigo,
                            "NU_ID_USUARIO":variablesGlobales.usuario.codusu}; 
        
        return this._http.post<any>(this.url + 'Inicia', bodyServicio);
    }
}