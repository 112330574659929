import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Disciplina } from 'src/app/@core/models/Disciplina';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Evento } from 'src/app/@core/models/Evento';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DisciplinaService } from 'src/app/@core/services/DisciplinaService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { EventoService } from 'src/app/@core/services/EventoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { DialogAgregarEquipoComponent } from './dialog-agregar-equipo/dialog-agregar-equipo.component';
import { MatSort } from '@angular/material/sort';
import { Fecha } from 'src/app/@core/models/Fecha';
import { DialogAgregarFechaComponent } from './dialog-agregar-fecha/dialog-agregar-fecha.component';
import { FechaService } from 'src/app/@core/services/FechaService';
import { EstadisticaService } from 'src/app/@core/services/EstadisticaService';
import { Reporte } from 'src/app/@core/models/Reporte';
import { Subscription } from 'rxjs';

enum Tabs{
  Equipos = 0,
  Fechas = 1,
  Estadistica = 2
}

@Component({
  selector: 'app-gestiona-equipo',
  templateUrl: './gestiona-equipo.component.html',
  styleUrls: ['./gestiona-equipo.component.scss']
})
export class GestionaEquipoComponent implements OnInit, OnDestroy {

  FrmGestEquipo!: FormGroup;
  ObjEvento!: Evento;
  Disciplina!: Disciplina[];
  principal:number=1;
  detalle:number=0;
  partido:number=0;
  ObjEquipo!: Equipo;
  ObjFecha!: Fecha;
  txtTitulo: string="";
  opcDetalle: number=0;
  codDisciplina: number=0;
  admEquipo: number=0;
  txtDisciplina!: string;
  isVisibleSerie: boolean=false;
  isVisibleBotonDetalle: boolean=false;
  isVisibleEstadisticaDetalle: boolean=false;
  tabIndex : Tabs = Tabs.Equipos;
  pageSizeOptions: number[]=[10, 20, 50];
  pageIndexEquipo: number=0;
  pageSizeEquipo: number =this.pageSizeOptions[0]; 
  pageIndexFecha: number=0;
  pageSizeFecha: number =this.pageSizeOptions[0];
  pageIndexEstadistica: number=0;
  pageSizeEstadistica: number =this.pageSizeOptions[0];
  navigationSubscription?: Subscription;

  Equipo: Equipo[]=[];
  displayedColumnsEquipo: string[] = [
    'nU_ID_EQUIPO',
    'nU_ANIO_PROMOCION',
    'tX_TURNO',
    'tX_DENOMINACION',
    'tX_SERIE',
    'tX_DELEGADO_TITULAR',
    'tX_DELEGADO_SUPLENTE'
  ];
  dataSourceEquipo = this.Equipo;

  EquipoTable : Equipo[] = [];
  EquipoTableDataSource = new MatTableDataSource(this.EquipoTable);

  Fecha: Fecha[]=[];
  displayedColumnsFecha: string[] = [
    'nU_ID_FECHA',
    'nU_SECUENCIA',
    'fE_PROGRAMADO',
    'fE_INICIO',
    'nU_ID_ESTADO'
  ];
  dataSourceFechaPartido = this.Fecha;

  FechaTable : Fecha[] = [];
  FechaTableDataSource = new MatTableDataSource(this.FechaTable);

  Estadistica: any[]=[];
  columnsEstadistica:any[]=[];
  displayedColumnsEstadistica: string[] = [];

  EstadisticaTableDataSource = new MatTableDataSource(this.Estadistica);
  
  EstadisticaDetalle: any[]=[];
  columnsEstadisticaDetalle:any[]=[];
  displayedColumnsEstadisticaDetalle: string[] = [];

  EstadisticaDetalleTableDataSource = new MatTableDataSource(this.EstadisticaDetalle);

  @ViewChild('equipoPaginator', { static: false }) equipoPaginator!: MatPaginator;
  @ViewChild('fechaPaginator', { static: false }) fechaPaginator!: MatPaginator;
  @ViewChild('estadisticaPaginator', { static: false }) estadisticaPaginator!: MatPaginator;
  @ViewChild('equipoSort', { static: false }) equipoSort!: MatSort;
  @ViewChild('fechaSort', { static: false }) fechaSort!: MatSort;
  @ViewChild('estadisticaSort', { static: false }) estadisticaSort!: MatSort;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjEventoService: EventoService,
              private ObjDisciplinaService: DisciplinaService,
              private ObjEquipoService: EquipoService,
              private ObjFechaService: FechaService,
              private ObjEstadisticaService: EstadisticaService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestEquipo=this._formBuilder.group({
      cbodisciplina:[null],
      cboreporte:["0"],
      cboserie:["A"]
    });
    
    this.admEquipo=variablesGlobales.usuarioRol.admEquipo;

    this.cargaDatos();

    /** Sección única que se ejecuta al hacer más de un clic sobre el menú de la url 
    que apunta a este componente (clicks sucesivos mientras este se encuentre aperturado o activo) **/
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.principal=1;
        this.detalle=0;
        this.partido=0;
        this.ObjEquipo={nU_ID_EQUIPO:0};
        this.ObjFecha={nU_ID_FECHA:0};
        this.txtTitulo="";
        this.opcDetalle=0;
        this.codDisciplina=0;      
        this.isVisibleSerie=false;
        this.isVisibleBotonDetalle=false;
        this.isVisibleEstadisticaDetalle=false;
        this.tabIndex=Tabs.Equipos;
        this.pageIndexEquipo=0;
        this.pageSizeEquipo=this.pageSizeOptions[0];
        this.pageIndexFecha=0;
        this.pageSizeFecha=this.pageSizeOptions[0];
        this.pageIndexEstadistica=0;
        this.pageSizeEstadistica=this.pageSizeOptions[0];
        this.Equipo=[];
        this.EquipoTableDataSource.data=this.Equipo; 
        this.EquipoTableDataSource.paginator=this.equipoPaginator;
        this.EquipoTableDataSource.sort = this.equipoSort;
        this.Fecha=[];
        this.FechaTableDataSource.data=this.Fecha; 
        this.FechaTableDataSource.paginator=this.fechaPaginator;
        this.FechaTableDataSource.sort = this.fechaSort;
        this.Estadistica=[];
        this.EstadisticaTableDataSource.data=this.Estadistica; 
        this.EstadisticaTableDataSource.paginator=this.estadisticaPaginator;
        this.EstadisticaTableDataSource.sort = this.estadisticaSort;

        this.FrmGestEquipo.controls["cbodisciplina"].setValue(null);
        this.FrmGestEquipo.controls["cboreporte"].setValue("0");
        this.FrmGestEquipo.controls["cboserie"].setValue("A");

        this.cargaDatos();
      }
    });
    /****/
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEventoService.Obtener(variablesGlobales.codevento).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjEvento=data.evento;
                      if(this.ObjEvento!=null) this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );

              this.fnListarDisciplina(); 
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                variablesGlobales.usuarioOficinaCargo.codOficina=0;
                variablesGlobales.usuarioOficinaCargo.codCargo=0;
                variablesGlobales.usuarioRol.admBase=0;
                variablesGlobales.usuarioRol.admEquipo=0;
                variablesGlobales.usuarioRol.admDelegado=0;
                variablesGlobales.usuarioRol.admDeportista=0;
                variablesGlobales.usuarioRol.isVisibleData=0;
                variablesGlobales.menu={};
                variablesGlobales.codevento=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  fnListarDisciplina(){
    this.ObjDisciplinaService.Listar(1).pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Disciplina= data.disciplina;
            if(this.Disciplina.length>0){
              this.FrmGestEquipo.controls["cbodisciplina"].setValue(this.Disciplina[0].nU_ID_DISCIPLINA);
              this.fnValorDisciplina(this.Disciplina[0].tX_DISCIPLINA!);

              this.LlenaGrillas(0, this.FrmGestEquipo.controls["cbodisciplina"].value);
            }
          }                                  
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  LlenaGrillas(opc: number, coddisciplina: number){
    if(opc==0){
      this.fnListarEquipo(coddisciplina);
      this.fnListarFecha(coddisciplina);
      this.contruyeColumnasEstadistica(this.FrmGestEquipo.controls["cboreporte"].value);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.fnListarEquipo(coddisciplina);
                this.fnListarFecha(coddisciplina);
                this.contruyeColumnasEstadistica(this.FrmGestEquipo.controls["cboreporte"].value);
              }
              else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  fnListarEquipo(coddisciplina: number){
    this.ObjEquipoService.Listar(1,variablesGlobales.codevento,coddisciplina)
      .subscribe(data => {
        if (data.isSuccess){
          this.Equipo= data.equipo;
          this.EquipoTableDataSource.data=this.Equipo;
        }                                         
      }); 

    this.EquipoTableDataSource.paginator=this.equipoPaginator;
    this.EquipoTableDataSource.sort = this.equipoSort;
  }

  fnListarFecha(coddisciplina: number){
    this.ObjFechaService.Listar(0,variablesGlobales.codevento,coddisciplina)
      .subscribe(data => {
        if (data.isSuccess){
          this.Fecha= data.fecha;
          this.FechaTableDataSource.data=this.Fecha;
        }                                         
      }); 

    this.FechaTableDataSource.paginator=this.fechaPaginator;
    this.FechaTableDataSource.sort = this.fechaSort;
  }

  contruyeColumnasEstadistica(reporte:string, opc?: number){
    var data_estadistica:any=[];
    this.pageIndexEstadistica=0;
    this.pageSizeEstadistica=this.pageSizeOptions[0];

    if(reporte=="0"){ //Tabla de posiciones
      this.isVisibleSerie=true;
      this.isVisibleBotonDetalle=false;
      this.isVisibleEstadisticaDetalle=false;
      this.columnsEstadisticaDetalle=[];
      this.displayedColumnsEstadisticaDetalle = [];
      this.EstadisticaDetalle= [];
      this.EstadisticaDetalleTableDataSource.data=this.EstadisticaDetalle;

      data_estadistica=[
        {idcolumna: "TX_SERIE", cabecera: "Serie", class: "estadistica-priority-posiciones-1"},
        {idcolumna: "NU_ANIO_PROMOCION", cabecera: "Equipo", class: "estadistica-priority-posiciones-2"},
        {idcolumna: "NU_PARTIDOS_JUGADOS", cabecera: "Partidos Jugados", class: "estadistica-priority-posiciones-3"},
        {idcolumna: "NU_PARTIDOS_GANADOS", cabecera: "Partidos Ganados", class: "estadistica-priority-posiciones-4"},
        {idcolumna: "NU_PARTIDOS_EMPATADOS", cabecera: "Partidos Empatados", class: "estadistica-priority-posiciones-5"},
        {idcolumna: "NU_PARTDOS_PERDIDOS", cabecera: "Partidos Perdidos", class: "estadistica-priority-posiciones-6"},
        {idcolumna: "NU_GOLES_AFAVOR_VALIDO", cabecera: "Goles a favor", class: "estadistica-priority-posiciones-7"},
        {idcolumna: "NU_GOLES_CONTRA_VALIDO", cabecera: "Goles en contra", class: "estadistica-priority-posiciones-8"},
        {idcolumna: "NU_DIFERENCIA_GOLES", cabecera: "Diferencia de Goles", class: "estadistica-priority-posiciones-9"},
        {idcolumna: "NU_PUNTOS_VALIDO", cabecera: "Puntos", class: "estadistica-priority-posiciones-10"},
      ];
    }
    else if(reporte=="1" || reporte=="2" || reporte=="3"){ //Resumen: Gol, TA, TR
      this.isVisibleSerie=true;
      this.isVisibleBotonDetalle=true;

      data_estadistica=[
        //{idcolumna: "NU_ID_INTEGRANTE", cabecera: "", class: "estadistica-priority-resumen-1"},
        {idcolumna: "TX_EVENTO", cabecera: "Evento", class: "estadistica-priority-resumen-2"},
        {idcolumna: "TX_DESCIPLINA", cabecera: "Disciplina", class: "estadistica-priority-resumen-3"},
        {idcolumna: "TX_ENTIDAD", cabecera: "Deportista", class: "estadistica-priority-resumen-4"},
        {idcolumna: "TX_EQUIPO", cabecera: "Equipo", class: "estadistica-priority-resumen-5"},
        {idcolumna: "NU_CANTIDAD_VALIDO", cabecera: "Cantidad", class: "estadistica-priority-resumen-6"}
      ];
    }
    else if(reporte=="4"){ //Resumen WO
      this.isVisibleSerie=false;
      this.isVisibleEstadisticaDetalle=false;
      this.isVisibleEstadisticaDetalle=false;
      this.columnsEstadisticaDetalle=[];
      this.displayedColumnsEstadisticaDetalle = [];
      this.EstadisticaDetalle= [];
      this.EstadisticaDetalleTableDataSource.data=this.EstadisticaDetalle;
      this.FrmGestEquipo.controls["cboserie"].setValue("A");

      data_estadistica=[
        {idcolumna: "TX_EVENTO", cabecera: "Evento", class: "estadistica-priority-wo-1"},
        {idcolumna: "TX_DESCIPLINA", cabecera: "Disciplina", class: "estadistica-priority-wo-2"},
        {idcolumna: "TX_EQUIPO", cabecera: "Equipo", class: "estadistica-priority-wo-3"},
        {idcolumna: "TX_SERIE", cabecera: "Serie", class: "estadistica-priority-wo-4"},
        {idcolumna: "NU_CANTIDAD", cabecera: "Cantidad", class: "estadistica-priority-wo-5"},
      ]; 
    }

    this.columnsEstadistica = data_estadistica.map((obj:any) => {
      return { 
        columnDef: obj.idcolumna,
        header: obj.cabecera,
        class: obj.class,
        cell: (element: any) => `${element[obj.idcolumna] ? element[obj.idcolumna] : `—`}`  
      }
    });

    this.displayedColumnsEstadistica = data_estadistica.map((obj:any) => obj.idcolumna);

    this.Estadistica= [];
    this.EstadisticaTableDataSource.data=this.Estadistica; 
    this.EstadisticaTableDataSource.paginator=this.estadisticaPaginator;
    this.EstadisticaTableDataSource.sort=this.estadisticaSort;

    this.fnListarEstadistica(this.FrmGestEquipo.controls["cboserie"].value, opc);
  }

  fnListarEstadistica(serie: string, opc?: number){
    let codcuadro=parseInt(this.FrmGestEquipo.controls["cboreporte"].value);
    let ObjReporte: Reporte ={
      nU_CODIGO_EVENTO:variablesGlobales.codevento,
      nU_CODIGO_DISCIPLINA:this.FrmGestEquipo.controls["cbodisciplina"].value,
    };

    if(this.isVisibleSerie){
      ObjReporte.tX_CODIGO_BUSQUEDA=serie;
    }

    switch(codcuadro){
      case 0:
        ObjReporte.nU_ID_REPORTE=codcuadro;
        break;
      case 1: 
        ObjReporte.nU_ID_REPORTE=1,
        ObjReporte.nU_ID_FILTRO=1,
        ObjReporte.nU_CODIGO_BUSQUEDA=2;
        break;
      case 2:
        ObjReporte.nU_ID_REPORTE=1,
        ObjReporte.nU_ID_FILTRO=1,
        ObjReporte.nU_CODIGO_BUSQUEDA=0;
        break;
      case 3:
        ObjReporte.nU_ID_REPORTE=1,
        ObjReporte.nU_ID_FILTRO=1,
        ObjReporte.nU_CODIGO_BUSQUEDA=1;
        break;
      case 4:
        ObjReporte.nU_ID_REPORTE=2;
        break;
    }

    if(opc==1){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjEstadisticaService.Listar(ObjReporte)
                  .subscribe(data => {
                    if (data.isSuccess){
                      this.Estadistica= data.partido;
                      this.EstadisticaTableDataSource.data=this.Estadistica;
                    }                                         
                  }); 

                this.EstadisticaTableDataSource.paginator=this.estadisticaPaginator;
                this.EstadisticaTableDataSource.sort = this.estadisticaSort;
              }
              else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
    else{
      this.ObjEstadisticaService.Listar(ObjReporte)
        .subscribe(data => {
          if (data.isSuccess){
            this.Estadistica= data.partido;
            this.EstadisticaTableDataSource.data=this.Estadistica;
          }                                         
        }); 

      this.EstadisticaTableDataSource.paginator=this.estadisticaPaginator;
      this.EstadisticaTableDataSource.sort = this.estadisticaSort;
    }
  }

  fnAgregarEquipo(){
    const dialogRef = this.dialog.open(DialogAgregarEquipoComponent, {
      data: {
        coddisciplina:parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value),
        listaEquipo: this.Equipo
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarEquipo(parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value));
      }
    });
  }

  fnAgregarFecha(idfecha: number, opc?: number){
    const dialogRef = this.dialog.open(DialogAgregarFechaComponent, {
      data: {
        coddisciplina:parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value),
        codfecha: idfecha,
        opc: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarFecha(parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value));
      }
    });
  }

  fnGestIntegrante(opc:number, obj: Equipo){
    this.principal=0;
    this.detalle=1;
    this.opcDetalle=opc;
    this.ObjEquipo=obj;
    this.codDisciplina=parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value);
  }

  fnRetornoGestIntegrante(objeto:any){
    this.principal=1;
    this.detalle=0;
    this.opcDetalle=0;
    this.ObjEquipo={nU_ID_EQUIPO:0};
    this.codDisciplina=0;

    setTimeout(() => {
      this.EquipoTableDataSource.paginator=this.equipoPaginator;
      this.EquipoTableDataSource.sort = this.equipoSort;
      this.FechaTableDataSource.paginator=this.fechaPaginator;
      this.FechaTableDataSource.sort = this.fechaSort;
      this.EstadisticaTableDataSource.paginator=this.estadisticaPaginator;
      this.EstadisticaTableDataSource.sort = this.estadisticaSort;
    });
  }

  fnGestPartido(obj: Fecha){
    this.principal=0;
    this.partido=1;
    this.ObjFecha=obj;
    this.codDisciplina=parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value);
  }

  fnRetornoGestPartido(objeto:any){
    this.principal=1;
    this.partido=0;
    this.ObjFecha={nU_ID_FECHA:0};
    this.codDisciplina=0;

    this.LlenaGrillas(1, parseFloat(this.FrmGestEquipo.controls["cbodisciplina"].value));
  }

  fnValorDisciplina(texto: string){
    this.txtDisciplina=texto;
  }

  getFormatFecha(opc: number, fecha: string){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);

    if(opc==1){
      parts=fecha.split("T");

      return dia+"/"+mes+"/"+anio+" "+parts[1].substring(0,8);
    }
    else{
      return dia+"/"+mes+"/"+anio;
    } 
  }

  handleEvent(matPaginator: string, event: PageEvent){
    if(matPaginator=="equipoPaginator"){
      this.pageIndexEquipo=event.pageIndex;
      this.pageSizeEquipo=event.pageSize;
    }
    else if(matPaginator=="fechaPaginator"){
      this.pageIndexFecha=event.pageIndex;
      this.pageSizeFecha=event.pageSize;
    }
    else if(matPaginator=="estadisticaPaginator"){
      this.pageIndexEstadistica=event.pageIndex;
      this.pageSizeEstadistica=event.pageSize;
    }
  }

}
