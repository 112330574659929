import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Evento } from 'src/app/@core/models/Evento';
import { Fecha } from 'src/app/@core/models/Fecha';
import { NotaPartido } from 'src/app/@core/models/NotaPartido';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAgregarNotapartidoComponent } from './dialog-agregar-notapartido/dialog-agregar-notapartido.component';
import { NotaPartidoService } from 'src/app/@core/services/NotaPartidoService';
import { DialogQuitarNotapartidoComponent } from './dialog-quitar-notapartido/dialog-quitar-notapartido.component';

@Component({
  selector: 'app-gestiona-nota-partido',
  templateUrl: './gestiona-nota-partido.component.html',
  styleUrls: ['./gestiona-nota-partido.component.scss']
})
export class GestionaNotaPartidoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestNotaPartido!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() ObjFecha!:Fecha;
  @Input() ObjPartido!:Partido;
  @Input() coddisciplina:number=0;
  @Input() txtDisciplina!:string;
  txtTitulo!: string;
  isBotonAgregar: number=0;

  NotaPartido: NotaPartido[]=[];
  displayedColumns: string[] = [
    'nU_ID_NOTAPARTIDO',
    'nU_ID_TIPO',
    'nU_ID_ENTIDAD',
    'tX_EQUIPO',
    'tX_OBSERVACION',
    'nU_ID_ESTADO'
  ];
  dataSource = this.NotaPartido;

  NotaPartidoTable : NotaPartido[] = [];
  NotaPartidoTableDataSource = new MatTableDataSource(this.NotaPartidoTable);

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjNotaPartidoService: NotaPartidoService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestNotaPartido=this._formBuilder.group({
    });

    this.isBotonAgregar=variablesGlobales.usuarioRol.admEquipo;
    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    this.cargaDatos();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.fnListarNota();
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarNota(){
    this.ObjNotaPartidoService.Listar(1,this.ObjPartido.nU_ID_PARTIDO)
      .subscribe(data => {
        if (data.isSuccess){
          this.NotaPartido= data.notaPartido;
          this.NotaPartidoTableDataSource.data=this.NotaPartido; 
        }                                         
      }); 

    this.NotaPartidoTableDataSource.paginator=this.paginator;
    this.NotaPartidoTableDataSource.sort = this.sort;
  }

  fnAgregarNota(idnotapartido: number){
    const dialogRef = this.dialog.open(DialogAgregarNotapartidoComponent, {
      data: {
        coddisciplina:this.coddisciplina,
        codfecha: this.ObjFecha.nU_ID_FECHA,
        objPartido: this.ObjPartido,
        codnotapartido: idnotapartido
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarNota();
      }
    });
  }

  fnQuitar(idnotapartido: number){
    const dialogRef = this.dialog.open(DialogQuitarNotapartidoComponent, {
      data: {
        codnotapartido: idnotapartido
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarNota();
      }
    });
  }

  getFormatFecha(opc: number, fecha: string){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);

    if(opc==1){
      parts=fecha.split("T");

      return dia+"/"+mes+"/"+anio+" "+parts[1].substring(0,8);
    }
    else{
      return dia+"/"+mes+"/"+anio;
    } 
  }

  Retorno(){
    this.salida.emit(1);
  }
}
