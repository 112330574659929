import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Documento } from '../models/Documento';

@Injectable({
    providedIn: 'root'
})

export class DocumentoService {
    public url:string = Configuracion.url2 + 'Documento/';
    
    constructor(private _http: HttpClient) { }

    Listar(){
        let bodyServicio = {"NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'filtrarUsuario', bodyServicio);
    }

    Obtener(id: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":id}; 
        return this._http.post<any>(this.url + 'ObtenerSolicitudSocio', bodyServicio);
    }

    Actualizar(variable: Documento){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;
        variable.nU_ID_OFICINA_ORIGEN=variablesGlobales.usuarioOficinaCargo.codOficina;
        variable.nU_ID_CARGO_ORIGEN=variablesGlobales.usuarioOficinaCargo.codCargo;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    ListarDocumento(opc: number, codigo: number, dato: string){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "TX_DATO_BUSQUEDA":dato}; 
        return this._http.post<any>(this.url + 'ListarDocumento', bodyServicio);
    }

    ObtenerCarnet(codigo: number, opc: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":codigo,
                            "NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'ObtenerCarnet', bodyServicio);
    }

    ValidaQR(opc: number, token: string){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":opc,
                            "TX_CODIGO_BUSQUEDA":token}; 
        return this._http.post<any>(this.url + 'Validadoc', bodyServicio);
    }
}