import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatRadioModule} from '@angular/material/radio';
import { MatMenuModule} from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import { MenuComponent } from './menu.component';
import { MenuRoutingModule } from './menu-routing.module';
import { InicioComponent } from './inicio/inicio.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { GestionaDocumentoComponent } from './movimientos/gestiona-documento/gestiona-documento.component';
import { DetalleDocumentoComponent } from './movimientos/gestiona-documento/detalle-documento/detalle-documento.component';
import { DialogAtenderDocumentoComponent } from './movimientos/gestiona-documento/dialog-atender-documento/dialog-atender-documento.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ActualizarDatosComponent } from './utilitarios/actualizar-datos/actualizar-datos.component';
import { DialogAgregarEquipoComponent } from './eventos/gestiona-equipo/dialog-agregar-equipo/dialog-agregar-equipo.component';
import { FichaInscripcionComponent } from './consultas/ficha-inscripcion/ficha-inscripcion.component';
import { GestionaEquipoComponent } from './eventos/gestiona-equipo/gestiona-equipo.component';
import { GestionaIntegranteComponent } from './eventos/gestiona-equipo/gestiona-integrante/gestiona-integrante.component';
import { RegistrarIntegranteComponent } from './eventos/gestiona-equipo/gestiona-integrante/registrar-integrante/registrar-integrante.component';
import { RegistrarEntidadComponent } from './controles/registrar-entidad/registrar-entidad.component';
import { DetalleDeportistaComponent } from './eventos/gestiona-equipo/gestiona-integrante/detalle-deportista/detalle-deportista.component';
import { GestionaBaseComponent } from './movimientos/gestiona-base/gestiona-base.component';
import { GestionaDirectivoComponent } from './movimientos/gestiona-base/gestiona-directivo/gestiona-directivo.component';
import { RegistrarDirectivoComponent } from './movimientos/gestiona-base/gestiona-directivo/registrar-directivo/registrar-directivo.component';
import { DialogAgregarBaseComponent } from './movimientos/gestiona-base/dialog-agregar-base/dialog-agregar-base.component';
import { BuscarDocumentoComponent } from './controles/buscar-documento/buscar-documento.component';
import { AgregarDocumentoComponent } from './controles/agregar-documento/agregar-documento.component';
import { GestionaUsuarioComponent } from './utilitarios/gestiona-usuario/gestiona-usuario.component';
import { RegistrarUsuarioComponent } from './utilitarios/gestiona-usuario/registrar-usuario/registrar-usuario.component';
import { AsignaRolComponent } from './utilitarios/gestiona-usuario/asigna-rol/asigna-rol.component';
import { ActualizaClaveComponent } from './utilitarios/actualiza-clave/actualiza-clave.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { RptCarnetComponent } from './eventos/gestiona-equipo/gestiona-integrante/rpt-carnet/rpt-carnet.component';
import { MatSortModule } from '@angular/material/sort';
import { DialogAgregarFechaComponent } from './eventos/gestiona-equipo/dialog-agregar-fecha/dialog-agregar-fecha.component';
import { GestionaPartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-partido.component';
import { DialogAgregarPartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/dialog-agregar-partido/dialog-agregar-partido.component';
import { DialogRegistrarReclamoComponent } from './eventos/gestiona-equipo/gestiona-partido/dialog-registrar-reclamo/dialog-registrar-reclamo.component';
import { GestionaNotaPartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-nota-partido/gestiona-nota-partido.component';
import { DialogAgregarNotapartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-nota-partido/dialog-agregar-notapartido/dialog-agregar-notapartido.component';
import { GestionaPlanillaJuegoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/gestiona-planilla-juego.component';
import { DialogSuspenderPartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/dialog-suspender-partido/dialog-suspender-partido.component';
import { DialogQuitarNotapartidoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-nota-partido/dialog-quitar-notapartido/dialog-quitar-notapartido.component';
import { DialogAgregarPlanillajuegoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/dialog-agregar-planillajuego/dialog-agregar-planillajuego.component';
import { DialogQuitarPlanillajuegoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/dialog-quitar-planillajuego/dialog-quitar-planillajuego.component';
import { GolesInfraccionesComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/goles-infracciones/goles-infracciones.component';
import { DialogQuitarDetalleplanillajuegoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/goles-infracciones/dialog-quitar-detalleplanillajuego/dialog-quitar-detalleplanillajuego.component';
import { InfraccionesComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/infracciones/infracciones.component';
import { DialogAgregarWoComponent } from './eventos/gestiona-equipo/gestiona-partido/gestiona-planilla-juego/dialog-agregar-wo/dialog-agregar-wo.component';

const PAGES_COMPONENTS = [
  MenuComponent,
];

@NgModule({
  imports: [
    MatProgressBarModule,
    MatProgressSpinnerModule,
    CommonModule,    
    MenuRoutingModule, 
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule, 
    MatTableModule,
    MatCardModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,    
    MatFormFieldModule,    
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatDialogModule,
    MatMenuModule,  
    MatCheckboxModule,
    MatTabsModule,
    MomentDateModule,
    DragDropModule,
    NgxQRCodeModule
  ],
  declarations: [
    ...PAGES_COMPONENTS,
    InicioComponent,
    GestionaDocumentoComponent,
    DetalleDocumentoComponent,
    DialogAtenderDocumentoComponent,
    ActualizarDatosComponent,
    DialogAgregarEquipoComponent,
    FichaInscripcionComponent,
    GestionaEquipoComponent,
    GestionaIntegranteComponent,
    RegistrarIntegranteComponent,
    RegistrarEntidadComponent,
    DetalleDeportistaComponent,
    GestionaBaseComponent,
    GestionaDirectivoComponent,
    RegistrarDirectivoComponent,
    DialogAgregarBaseComponent,
    BuscarDocumentoComponent,
    AgregarDocumentoComponent,
    GestionaUsuarioComponent,
    RegistrarUsuarioComponent,
    AsignaRolComponent,
    ActualizaClaveComponent,
    RptCarnetComponent,
    DialogAgregarFechaComponent,
    GestionaPartidoComponent,
    DialogAgregarPartidoComponent,
    DialogRegistrarReclamoComponent,
    GestionaNotaPartidoComponent,
    DialogAgregarNotapartidoComponent,
    GestionaPlanillaJuegoComponent,
    DialogSuspenderPartidoComponent,
    DialogQuitarNotapartidoComponent,
    DialogAgregarPlanillajuegoComponent,
    DialogQuitarPlanillajuegoComponent,
    GolesInfraccionesComponent,
    DialogQuitarDetalleplanillajuegoComponent,
    InfraccionesComponent,
    DialogAgregarWoComponent,
  ],
  entryComponents: [
    DialogAtenderDocumentoComponent,
    DialogAgregarEquipoComponent,
    RegistrarIntegranteComponent,
    RegistrarEntidadComponent,
    RegistrarDirectivoComponent,
    DialogAgregarBaseComponent,
    BuscarDocumentoComponent,
    AgregarDocumentoComponent,
    RegistrarUsuarioComponent,
    AsignaRolComponent,
    RptCarnetComponent,
    DialogAgregarFechaComponent,
    DialogAgregarPartidoComponent,
    DialogRegistrarReclamoComponent,
    DialogAgregarNotapartidoComponent,
    DialogSuspenderPartidoComponent,
    DialogQuitarNotapartidoComponent,
    DialogAgregarPlanillajuegoComponent,
    DialogQuitarPlanillajuegoComponent,
    GolesInfraccionesComponent,
    DialogQuitarDetalleplanillajuegoComponent,
    InfraccionesComponent,
    DialogAgregarWoComponent
  ],
})
export class MenuModule { }