export const Configuracion = {  
    //Desarrollo
    // url_iconos1:'https://service2.rakler.pe/autentica/assets/iconos/',    
    // url_iconos2:"https://localhost:28411/assets/iconos/",    // 
    // url_iconos3:'https://service2.rakler.pe/sunat/assets/iconos/',
    //url2: "https://localhost:28411/",
    //iniciosolicitud: "http://localhost:4200/#/portal", 
    //portalprincipal: "http://localhost:4200/", 
    url5: "https://localhost:4200/",

    //test
    //url2: "https://ws-test.rakler.pe/sigexacemba/",
    url2: "https://ws-test.rakler.pe/sigexsadj/",
    //iniciosolicitud: "https://demo.rakler.org.pe/#/portal",
    //iniciosolicitud: "https://test-padron.rakler.org.pe/#/portal", 
    //portalprincipal: "https://test-portal.rakler.org.pe/", 

    //produccion
    url1: "https://service2.rakler.pe/autentica/",
    //url2: "https://service2.rakler.pe/sigexacemba/",
    url3: "https://service2.rakler.pe/sunat/",
    iniciosolicitud: "https://padron.rakler.org.pe/#/portal", 
    portalprincipal: "https://portal.rakler.org.pe/", 
    url4: "https://service2.rakler.pe/ciudadano/"
}