
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{titulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarPlanillaJuego"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila0">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Deportista" formControlName="codintegrante" class="CampoTextArea"
                            [matAutocomplete]="auto1" (focus)="fnListarIntegrante(1)" (keydown)="pulsar($event)" required></textarea>
                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnIntegrante">
                            <mat-option class="opcionSelect" *ngFor="let integrante of filteredIntegrante | async" [value]="integrante">                            
                                {{integrante.tX_NUMERO+' | '+integrante.tX_NOMBRE_COMPLETO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila2">
                        <mat-select style="width: 150px;" placeholder="En calidad" formControlName="cbotipo" required (selectionChange)="fnHabilitarSaliente($event.value)">
                            <mat-option value="0">TITULAR</mat-option>
                            <mat-option value="1">CAMBIO</mat-option>
                            <mat-option value="2">DELEGADO</mat-option>
                            <mat-option value="3">COMPLETA</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila1">
                        <input matInput placeholder="Nro. Carnet" formControlName="txtnrocarnet" autocomplete="off" required
                            #inputNroCarnet (keydown)="onKeyDownOnlyNumber(inputNroCarnet,$event)" (paste)="handlePasteOnlyNumber($event)">
                    </mat-form-field>
                    <mat-form-field class="Fila1" *ngIf="isVisibleCamiseta">
                        <input matInput placeholder="Nro. Camiseta" formControlName="txtnrocamiseta" autocomplete="off" required
                            #inputNroCamiseta (keydown)="onKeyDownOnlyNumber(inputNroCamiseta,$event)" (paste)="handlePasteOnlyNumber($event)">
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleCapitan">
                    <mat-form-field class="Fila1">
                        <mat-select style="width: 104px;" placeholder="¿Es capitán?" formControlName="cbocapitan" required>
                            <mat-option value="0">NO</mat-option>
                            <mat-option value="1">SI</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleSaliente">
                    <mat-form-field class="Fila0">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Saliente" formControlName="codintegrante_saliente" class="CampoTextArea"
                            [matAutocomplete]="auto2" (focus)="fnListarIntegrante(2)" (keydown)="pulsar($event)" required></textarea>
                        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnIntegrante">
                            <mat-option class="opcionSelect" *ngFor="let integrante of filteredIntegrante | async" [value]="integrante">                            
                                {{integrante.tX_NUMERO+' | '+integrante.tX_NOMBRE_COMPLETO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleSaliente">
                    <mat-form-field class="Fila3">
                        <input matInput placeholder="Tiempo transcurrido del juego" formControlName="txttiempo" autocomplete="off" required
                            #inputTiempo (keydown)="onKeyDownOnlyNumber(inputTiempo,$event)" (paste)="handlePasteOnlyNumber($event)">
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>