import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Configuracion } from './config';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ReporteService {
    public url: string = Configuracion.url5 + 'Reporte/';
    constructor(private _http: HttpClient) { }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        return throwError(errorMessage);
    }

    public CarnetPDF(template: string): Observable<any> {
        return this._http.post(`${this.url}CarnetPDF`, { template },
            {
                reportProgress: true,
                responseType: 'blob'
            }
        ).pipe(retry(3), catchError(this.handleError));
    }
}