import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Evento } from 'src/app/@core/models/Evento';
import { Fecha } from 'src/app/@core/models/Fecha';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAgregarPartidoComponent } from './dialog-agregar-partido/dialog-agregar-partido.component';
import { PartidoService } from 'src/app/@core/services/PartidoService';
import { DialogSuspenderPartidoComponent } from './dialog-suspender-partido/dialog-suspender-partido.component';
import { DialogRegistrarReclamoComponent } from './dialog-registrar-reclamo/dialog-registrar-reclamo.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestiona-partido',
  templateUrl: './gestiona-partido.component.html',
  styleUrls: ['./gestiona-partido.component.scss']
})
export class GestionaPartidoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestPartido!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() ObjFecha!:Fecha;
  @Input() coddisciplina:number=0;
  @Input() txtDisciplina!:string;
  partido:number=1;
  integrante_partido:number=0;
  nota:number=0;
  txtTitulo!: string;
  ObjPartido!: Partido;
  isBotonAgregar: number=0;
  pageSizeOptions: number[]=[10, 20, 50];
  pageIndex: number=0;
  pageSize: number =this.pageSizeOptions[0]; 

  Partido: Partido[]=[];
  displayedColumns: string[] = [
    'nU_ID_PARTIDO',
    'nU_SECUENCIA',
    'tX_SERIE',
    'fE_PROGRAMADO',
    'nU_ID_EQUIPO_LOCAL',
    'fE_INICIO',
    'nU_ID_EQUIPO_LOCAL_GOLES',
    'nU_ID_EQUIPO_GANADOR',
    'nU_ID_ESTADO'
  ];
  dataSource = this.Partido;

  PartidoTable : Partido[] = [];
  PartidoTableDataSource = new MatTableDataSource(this.PartidoTable);

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjPartidoService: PartidoService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestPartido=this._formBuilder.group({
    });

    this.isBotonAgregar=variablesGlobales.usuarioRol.admEquipo;
    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    this.cargaDatos();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.fnListarPartido();
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarPartido(){
    this.ObjPartidoService.Listar(1,this.ObjFecha.nU_ID_FECHA)
      .subscribe(data => {
        if (data.isSuccess){
          this.Partido= data.partido;
          this.PartidoTableDataSource.data=this.Partido; 
        }                                         
      }); 

    this.PartidoTableDataSource.paginator=this.paginator;
    this.PartidoTableDataSource.sort = this.sort;
  }

  fnAgregarPartido(idpartido: number){
    const dialogRef = this.dialog.open(DialogAgregarPartidoComponent, {
      data: {
        coddisciplina:this.coddisciplina,
        objFecha: this.ObjFecha,
        codpartido: idpartido
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPartido();
      }
    });
  }

  fnSuspenderPartido(idpartido: number, opc: number){
    const dialogRef = this.dialog.open(DialogSuspenderPartidoComponent, {
      data: {
        codpartido: idpartido,
        opc: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPartido();
      }
    });
  }

  fnRegReclamo(obj: Partido){
    const dialogRef = this.dialog.open(DialogRegistrarReclamoComponent, {
      data: {
        objPartido: obj
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPartido();
      }
    });
  }

  fnGestPlanilla(obj: Partido){
    this.partido=0;
    this.integrante_partido=1;
    this.ObjPartido=obj;
  }

  fnRetornoGestPlanilla(objeto:any){
    this.partido=1;
    this.integrante_partido=0;
    this.ObjPartido={nU_ID_PARTIDO:0};

    setTimeout(() => {
      this.PartidoTableDataSource.paginator=this.paginator;
      this.PartidoTableDataSource.sort = this.sort;
    });
  }

  fnGestNota(obj: Partido){
    this.partido=0;
    this.nota=1;
    this.ObjPartido=obj;
  }

  fnRetornoGestNota(objeto:any){
    this.partido=1;
    this.nota=0;
    this.ObjPartido={nU_ID_PARTIDO:0};

    setTimeout(() => {
      this.PartidoTableDataSource.paginator=this.paginator;
      this.PartidoTableDataSource.sort = this.sort;
    });
  }

  fnIniciarPartido(idpartido: number){
    Swal.fire({
      text: "¿Está seguro de iniciar el partido?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.ObjPartidoService.Inicia(idpartido).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                          
                          this.fnListarPartido();
                        }
                        else{
                          console.log(data.tx_mensaje);
                          Swal.fire(
                            'Advertencia',
                            'Atención: Error al procesar',
                            'warning'
                          );
                        } 
                      },
                      error => {
                        console.log(error);
                        Swal.fire(
                          'Error',
                          'El servicio solicitado no se encuentra disponible',
                          'error'
                        );
                      }
                    );
                }
                else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  this.router.navigate(['login']);
                }
              }
            },
            error => {
              console.log(error);
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }   
          );
      }
    });
  }

  getFormatFecha(opc: number, fecha: string){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);

    if(opc==1){
      parts=fecha.split("T");

      return dia+"/"+mes+"/"+anio+" "+parts[1].substring(0,8);
    }
    else{
      return dia+"/"+mes+"/"+anio;
    } 
  }

  handleEvent(event: PageEvent){
    this.pageIndex=event.pageIndex;
    this.pageSize=event.pageSize;
  }

  Retorno(){
    this.salida.emit(1);
  }

}
