import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DetallePlanillaJuego } from 'src/app/@core/models/DetallePlanillaJuego';
import { PlanillaJuego } from 'src/app/@core/models/PlanillaJuego';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DetallePlanillaJuegoService } from 'src/app/@core/services/DetallePlanillaJuegoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogQuitarDetalleplanillajuegoComponent } from './dialog-quitar-detalleplanillajuego/dialog-quitar-detalleplanillajuego.component';
import Swal from 'sweetalert2';
import { PlanillaJuegoService } from 'src/app/@core/services/PlanillaJuegoService';

@Component({
  selector: 'app-goles-infracciones',
  templateUrl: './goles-infracciones.component.html',
  styleUrls: ['./goles-infracciones.component.scss']
})
export class GolesInfraccionesComponent implements OnInit {

  FrmGestGolesInfracciones!: FormGroup;
  coddisciplina:number=0;
  codfecha:number=0;
  codpartido:number=0;
  codplanillajuego:number=0;
  ObjPlanillaJuego!:PlanillaJuego;
  txtentidad!:string;
  isBotonAgregar: number=0;

  DetallePlanillaJuego: DetallePlanillaJuego[]=[];
  displayedColumns: string[] = [
    'nU_ID',
    'nU_CANTIDAD',
    'tX_TIEMPO'
  ];
  dataSource = this.DetallePlanillaJuego;

  DetallePlanillaJuegoTable : DetallePlanillaJuego[] = [];
  DetallePlanillaJuegoTableDataSource = new MatTableDataSource(this.DetallePlanillaJuegoTable);

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjPlanillaJuegoService: PlanillaJuegoService,
              private ObjDetallePlanillaJuegoService: DetallePlanillaJuegoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<GolesInfraccionesComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddisciplina=data.coddisciplina;
                this.codfecha=data.codfecha;
                this.codpartido=data.codpartido;
                this.codplanillajuego=data.codplanillajuego;
                this.txtentidad=data.txtentidad;
              }

  ngOnInit(): void {
    this.FrmGestGolesInfracciones=this._formBuilder.group({
      cbotiempo:[null,Validators.required]
    });

    this.isBotonAgregar=variablesGlobales.usuarioRol.admEquipo;

    this.cargaDatos();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjPlanillaJuegoService.Obtener(this.codplanillajuego).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.ObjPlanillaJuego=data.planillaJuego;
                  }
                });

              this.fnListarDetallePlanillaJuego();
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarDetallePlanillaJuego(){
    this.ObjDetallePlanillaJuegoService.Listar(0,this.codplanillajuego).pipe()
      .subscribe(data => {
        if(data.isSuccess){
          this.DetallePlanillaJuego=data.partido;
          this.DetallePlanillaJuego=this.DetallePlanillaJuego.filter((value,key)=>{
            return value.nU_ID_ESTADO != 0 && value.nU_ID_PRODUCTO==2;
          });
          this.DetallePlanillaJuegoTableDataSource.data=this.DetallePlanillaJuego; 
        }                                         
      });
  }

  Guardar(){
    if (this.FrmGestGolesInfracciones.controls["cbotiempo"].value == null || 
      this.FrmGestGolesInfracciones.controls["cbotiempo"].value.length == 0) {
        this.alertMsg('Seleccione Tiempo');
    }
    else{
      let ObjDetallePlanillaJuego: DetallePlanillaJuego={nU_ID: 0};
      ObjDetallePlanillaJuego.nU_ID_EVENTO=variablesGlobales.codevento;
      ObjDetallePlanillaJuego.nU_ID_DISCIPLINA=this.coddisciplina;
      ObjDetallePlanillaJuego.nU_ID_FECHA=this.codfecha;
      ObjDetallePlanillaJuego.nU_ID_PARTIDO=this.codpartido;
      ObjDetallePlanillaJuego.nU_ID_EQUIPOPARTIDO=this.ObjPlanillaJuego.nU_ID_EQUIPOPARTIDO;
      ObjDetallePlanillaJuego.nU_ID_EQUIPO=this.ObjPlanillaJuego.nU_ID_EQUIPO;
      ObjDetallePlanillaJuego.nU_ID_PLANILLAJUEGO=this.ObjPlanillaJuego.nU_ID_PLANILLAJUEGO;
      ObjDetallePlanillaJuego.nU_ID_INTEGRANTE=this.ObjPlanillaJuego.nU_ID_INTEGRANTE;
      ObjDetallePlanillaJuego.nU_ID_ENTIDAD=this.ObjPlanillaJuego.nU_ID_ENTIDAD;
      ObjDetallePlanillaJuego.nU_ID_PRODUCTO=2;
      ObjDetallePlanillaJuego.nU_CANTIDAD=1;
      ObjDetallePlanillaJuego.nU_TIEMPO=parseInt(this.FrmGestGolesInfracciones.controls["cbotiempo"].value);
      ObjDetallePlanillaJuego.tX_OBSERVACION="";
      ObjDetallePlanillaJuego.nU_ID_ESTADO=1;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjDetallePlanillaJuegoService.Actualizar(ObjDetallePlanillaJuego).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.fnListarDetallePlanillaJuego();
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  fnQuitar(opc: number, iddetalleplanillajuego: number){
    const dialogRef = this.dialog.open(DialogQuitarDetalleplanillajuegoComponent, {
      data: {
        coddetalleplanillajuego: iddetalleplanillajuego,
        opc: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarDetallePlanillaJuego();
      }
    });
  }

  Close() {
    this.dialogRef.close();
  }

}
