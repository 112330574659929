import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Aux_Solicitud } from '../models/Aux_Solicitud';
import { Solicitud } from '../models/Solicitud';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class SolicitudPadronService {
    public url:string = Configuracion.url2 + 'SolicitudPadron/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: Aux_Solicitud){
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    Obtener(token: string){
        let bodyServicio = {"TX_TOKEN":token}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    ValidaToken(token: string){
        let bodyServicio = {"TX_TOKEN":token}; 
        return this._http.post<any>(this.url + 'ValidaToken', bodyServicio);
    }

    Presentar(variable: Solicitud){
        
        //--Telefono
        let bodyServicio = {"NU_ID_CODIGO":variable.objAux_Solicitud?.nU_ID_CODIGO,
                            "FE_NACIMIENTO":variable.objAux_Solicitud?.fE_NACIMIENTO,
                            "NU_ANIO_EGRESO":variable.objAux_Solicitud?.nU_ANIO_EGRESO,
                            "TX_TURNO":variable.objAux_Solicitud?.tX_TURNO,
                            "TX_DIRECCION":variable.objEntidad?.objEstablecimiento?.tX_DIRECCION,
                            "TX_UBIGEO":variable.objEntidad?.objEstablecimiento?.tX_UBIGEO,
                            "TX_NUMERO":variable.objEntidad?.objTelefono?.tX_NUMERO,
                            "TX_SEXO":variable.objAux_Solicitud?.tX_SEXO,
                            "TX_OBSERVACION":variable.tX_OBSERVACION
                            }; 
                            //console.log(bodyServicio);
        return this._http.post<any>(this.url + 'Presentar', bodyServicio);
    } 

    Procesar(id: number, observado: number, motivo: string){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":id,
                            "NU_OPCION_BUSQUEDA":observado,
                            "TX_DATO_BUSQUEDA":motivo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu
                            }; 
        return this._http.post<any>(this.url + 'Procesar', bodyServicio);
    } 
}